import { Checkbox, Col, Form, Radio, Row, Space, Switch } from 'antd'
import { FormInstance } from 'antd/es/form/Form'

import { TimeRanges } from 'components/reusableSelector/timeRange'

import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'

import { theme } from 'theme'

interface props {
  form: FormInstance<any>
  scheduleType: string
  checkedDays: Record<string, boolean>
  handleScheduleTypeChange: (e: any) => void
  handleDayCheckedChange: (day: string, isChecked: boolean) => void
}

export const ScheduleSectionModal: React.FC<props> = ({
  form,
  scheduleType,
  checkedDays,
  handleScheduleTypeChange,
  handleDayCheckedChange,
}) => {
  const { scheduleText, weekdayText, weekendText } = usePeakShavingTranslation()
  const { monText, tueText, wedText, thuText, friText, satText, sunText, customDaysText } = useAccessPlanTranslation()

  const renderDaySwitch = (dayKey: string, dayText: string, timeRangeName: string, isChecked: boolean) => (
    <div className="time-section">
      <div className="time-switch">
        <Switch checked={isChecked} onChange={() => handleDayCheckedChange(dayKey, !isChecked)} />
        <span className="day-text">{dayText}</span>
      </div>
      {isChecked && <TimeRanges name={timeRangeName} />}
    </div>
  )

  const renderDayCheckbox = (dayKey: string, dayText: string, timeRangeName: string, isChecked: boolean) => (
    <>
      <Checkbox value={dayKey} checked={isChecked} onChange={() => handleDayCheckedChange(dayKey, !isChecked)}>
        <div className="paragraph-01-regular" style={{ marginBottom: '10px' }}>
          {dayText}
        </div>
      </Checkbox>
      {isChecked && <TimeRanges name={timeRangeName} />}
    </>
  )

  return (
    <div>
      <div className="heading-06-regular">
        {scheduleText}
        <span style={{ color: 'red' }}>*</span>
      </div>
      <Form.Item name="scheduleType">
        <Radio.Group onChange={handleScheduleTypeChange} value={scheduleType} style={{ width: '100%' }}>
          <Space direction="vertical" size={0} style={{ width: '100%', gap: 'initial' }}>
            <RadioCard
              value="24/7"
              isSelected={scheduleType === '24/7'}
              label="24/7"
              theme={theme}
              borderRadius="8px 8px 0px 0px"
            />

            <RadioCard
              value="weekdayWeekend"
              isSelected={scheduleType === 'weekdayWeekend'}
              label={`${weekdayText}/${weekendText}`}
              theme={theme}
              borderRadius="0px"
            />

            {form.getFieldValue('scheduleType') === 'weekdayWeekend' && (
              <div style={{ ...dayGroupStyles, borderRadius: '0px' }}>
                <>
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row>
                      <Col span={24}>
                        {renderDayCheckbox(
                          'weekdayChecked',
                          `${weekdayText} (${monText} - ${friText})`,
                          'weekdayRates',
                          checkedDays.weekdayChecked,
                        )}
                      </Col>
                      <Col span={24}>
                        {renderDayCheckbox(
                          'weekendChecked',
                          `${weekendText} (${satText} - ${sunText})`,
                          'weekendRates',
                          checkedDays.weekendChecked,
                        )}
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </>
              </div>
            )}

            {/* Custom Days Radio and Switches */}
            <RadioCard
              value="customDays"
              isSelected={scheduleType === 'customDays'}
              label={customDaysText}
              theme={theme}
              borderRadius={scheduleType === 'customDays' ? '0px' : '0px 0px 8px 8px'}
            />
            <Form.Item
              shouldUpdate={(prevValues, currentValues) => prevValues.scheduleType !== currentValues.scheduleType}
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              {({ getFieldValue }) =>
                getFieldValue('scheduleType') === 'customDays' && (
                  <div style={{ ...dayGroupStyles, borderRadius: '0px 0px 8px 8px' }}>
                    {renderDaySwitch('mondayChecked', monText, 'mondayRates', checkedDays.mondayChecked)}
                    <hr />
                    {renderDaySwitch('tuesdayChecked', tueText, 'tuesdayRates', checkedDays.tuesdayChecked)}
                    <hr />
                    {renderDaySwitch('wednesdayChecked', wedText, 'wednesdayRates', checkedDays.wednesdayChecked)}
                    <hr />
                    {renderDaySwitch('thursdayChecked', thuText, 'thursdayRates', checkedDays.thursdayChecked)}
                    <hr />
                    {renderDaySwitch('fridayChecked', friText, 'fridayRates', checkedDays.fridayChecked)}
                    <hr />
                    {renderDaySwitch('saturdayChecked', satText, 'saturdayRates', checkedDays.saturdayChecked)}
                    <hr />
                    {renderDaySwitch('sundayChecked', sunText, 'sundayRates', checkedDays.sundayChecked)}
                  </div>
                )
              }
            </Form.Item>
          </Space>
        </Radio.Group>
      </Form.Item>
    </div>
  )
}

interface RadioCardProps {
  value: string
  isSelected: boolean
  label: string
  borderRadius: string
  theme: {
    colors: {
      primary: string
      neonGreenShade: string
      lightGrey: string
    }
  }
}

const RadioCard: React.FC<RadioCardProps> = ({ value, isSelected, label, theme, borderRadius }) => (
  <div
    style={{
      padding: '12px 24px',
      borderRadius: borderRadius,
      backgroundColor: isSelected ? theme.colors.neonGreenShade : '',
      border: isSelected ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.lightGrey}`,
    }}
  >
    <Radio value={value} className="circle-radio">
      <span className="paragraph-01-regular">{label}</span>
    </Radio>
  </div>
)

const dayGroupStyles = {
  background: theme.colors.ternary25,
  width: '100%',
  padding: '16px 48px',
  border: `1px solid ${theme.colors.lightGrey}`,
}
