import { Col, Form, Row, Select, Space } from 'antd'
import { plusSVG } from 'assets/svg/plus'
import { trashcanSVG } from 'assets/svg/trashcan'
import { ButtonModal } from 'atom/button'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import { PricingSchemaEventSchedule } from 'models/price'

interface props {
  name: string
}

export const TimeRanges: React.FC<props> = ({ name }) => {
  const { toText } = usePricingSchemasTranslation()
  const { addTimeScheduleText } = useAccessPlanTranslation()

  const eventSchedules: PricingSchemaEventSchedule[] = Array.from(Array(24), (_, index) => ({
    from: index,
    to: index + 1,
    dollar_rate: '1',
  }))

  return (
    <Col span={24}>
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <div
            style={{
              position: 'relative',
            }}
          >
            <div>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} style={{ marginTop: '0px', marginLeft: '28px' }}>
                  <Space key={key} style={{ marginBottom: 8, alignItems: 'flex-start' }} align="baseline">
                    <div className="time-container">
                      <Form.Item
                        name={[key, 'from']}
                        validateTrigger={['onChange', 'onSelect']}
                        style={{ marginBottom: '8px', marginTop: '0px' }}
                      >
                        <Select style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                          {eventSchedules.map((eventSchedule, _) => {
                            return (
                              <Select.Option
                                value={eventSchedule.from}
                                key={eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`}
                              >
                                {eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>{toText}</div>
                    <div className="time-container">
                      <Form.Item name={[key, 'to']} style={{ marginBottom: '8px', marginTop: '0px' }}>
                        <Select style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                          {eventSchedules.map((eventSchedule, _) => {
                            return (
                              <Select.Option
                                value={eventSchedule.to}
                                key={eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                              >
                                {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <ButtonModal type="link" icon={trashcanSVG} click={() => remove(name)} />
                  </Space>
                </div>
              ))}
            </div>
            <Row className="add-button">
              <Form.Item style={{ marginTop: '0px', marginLeft: '7px' }}>
                <div className="addTimeScheduleButton" style={{ marginLeft: '5px', marginBottom: '5px' }}>
                  <ButtonModal click={add} type="text" icon={plusSVG} text={addTimeScheduleText} />
                </div>
              </Form.Item>
            </Row>
          </div>
        )}
      </Form.List>
    </Col>
  )
}
