import { Button, Col, Row, Space, Tabs, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { ResponsiveTable } from '../../atom/responsive-table'
import { AlertError } from '../../components/error'
import { SwtchError } from '../../models/error'
import { PaginationMeta } from '../../models/pagination'
import { TenantRef } from '../../models/tenant'
import { AugmentedUser, DashboardAccessType, toAugmentedUser, User, UserWallet } from '../../models/user'
import { GetUsers } from '../../services/data-provider/users'
import { useAppState } from '../../state'
import { TableWrapper } from '../../atom/table-wrapper'
import { MultiTenantSelector } from '../../components/redesign/multiple-tenant-selector'
import { UserInvitationRedesign } from './user-invitation-redesign'
import { UserEditForm } from './user-edit-redesign'
import { editSVG } from '../../assets/svg/edit'
import { plusSVG } from '../../assets/svg/plus'
import { ButtonIcon, ButtonModal } from '../../atom/button'
import { magnifyBlackSVG } from '../../assets/svg/magnify'
import { greyInfoSVG } from '../../assets/svg/greyInfo'
import { FindTenants } from '../../services/data-provider/tenants'
import { SubscriptionsRedesign } from '../../components/subscriptions/subscriptions-redesign'
import { PlansRedesign } from '../../components/plans/plans-redesign'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import { NoIconSearch } from '../../components/redesign/no-icon-search'
import {
  CustomBox,
  DriverSvgSpacing,
  EditButtonWrapper,
  InviteButtonCircleWrapper,
  InviteButtonWrapper,
  LineBreak,
  MultiTenantMobileStyling,
  MultiTenantStyling,
  SearchButtonContainer,
  SearchButtonWrapper,
  TableStyling,
  TabsButtonWrapper,
  TabsWrapper,
  UserSelectorStyling,
  UserTitleMobileWrapper,
} from '../../atom/users-page-redesign-styles'
import { UserSelectorNoTagRedesign } from '../../components/selector/user-selector-no-tag'
import { ButtonIconCircle } from '../../atom/button'
import UsersCollapse from './users-collapse'
import { ButtonTextSpacing } from '../../atom/user-invite'
import { useMediaQuery } from 'react-responsive'

export const UsersRedesignBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  const responsiveHeaderTitleTabletAndLaptop = useMediaQuery({ maxWidth: 1233, minWidth: 0 })
  const showFullInviteButtonTabletAndLaptop = useMediaQuery({ maxWidth: 1233, minWidth: 856 })
  const showFullInviteButtonMobileAndTablet = useMediaQuery({ maxWidth: 758, minWidth: 0 })
  const responsiveLineBreak = useMediaQuery({ maxWidth: 1233, minWidth: 993 })
  const responsiveSearchButtonForMobile = useMediaQuery({ maxWidth: 492, minWidth: 0 })
  const showCollapsedTable = useMediaQuery({ maxWidth: 767, minWidth: 0 })
  const { currentUser, selectedTenant, IsLaptop, IsDesktop, IsTablet, IsMobile } = useAppState()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [users, setUsers] = useState<AugmentedUser[]>([])
  const [term, setTerm] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<AugmentedUser>()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>()
  //const [currentPage, setCurrentPage] = useState(1) //Commented out in case need in the future
  const [showInvitationForm, setShowInvitationForm] = useState(false)
  const [showEditUserNameForm, setEditUserNameForm] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [currentAugmentedUser, setCurrentAugmentedUser] = useState<AugmentedUser>()
  const [tab, setTab] = useState('overview')
  const [createPlanForm, setShowCreatePlanForm] = useState(false)
  const [showInviteForm, setShowInviteForm] = useState(false)
  const [subscriptionEmailSearch, setSubscriptionEmailSearch] = useState<UserWallet | undefined>()
  const [searchSubscriptions, setSearchSubscriptions] = useState(false)
  const [open, setOpen] = useState(true)
  const {
    emailTxt,
    driverInfoTxt,
    driverTxt,
    inviteUser,
    tenantsTxt,
    createPlanText,
    subscribeUserText,
    idText,
    userText,
    accessText,
    noAccessText,
    roleTxt,
    overviewText,
    plansText,
    subscriptionsText,
  } = useUserTranslation()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const isLaptop = IsLaptop()

  const fetchUsers = (page = 1) => {
    setLoading(true)
    setError(undefined)
    let pageNumber = 1
    //To handle event getting passed from Button onClick
    if (typeof page === 'number') {
      pageNumber = page
    }
    if (tenants.length === 0) {
      if (currentUser?.role === 'admin') {
        GetUsers(pageNumber, term)
          .then((resp) => {
            setUsers(resp.data)
            setPagination(resp.pagination)
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false))
      } else {
        FindTenants(false)
          .then((tenantsForUser) => {
            GetUsers(pageNumber, term, selectedTenant, tenantsForUser)
              .then((resp) => {
                setUsers(resp.data)
                setPagination(resp.pagination)
              })
              .catch((error) => setError(error))
              .finally(() => setLoading(false))
          })
          .catch((err: SwtchError) => {
            message.error(err.description)
          })
      }
    } else {
      GetUsers(pageNumber, term, selectedTenant, tenants)
        .then((resp) => {
          setUsers(resp.data)
          setPagination(resp.pagination)
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const tenantScope: TenantRef[] = selectedTenant
    ? [
        {
          id: selectedTenant.id,
          name: selectedTenant.name,
          displayName: selectedTenant.displayName,
          combineName: selectedTenant.combineName,
        },
      ]
    : tenants
  const hasTenantScope = tenantScope !== undefined

  useEffect(() => {
    if (selectedTenant) {
      setTenants([
        {
          id: selectedTenant.id,
          name: selectedTenant.name,
          displayName: selectedTenant.displayName,
          combineName: selectedTenant.combineName,
        },
      ])
    }
    let currentAugmentedUserTemp = currentUser && toAugmentedUser(currentUser)
    setCurrentAugmentedUser(currentAugmentedUserTemp)
  }, [])

  const handleUserInvited = (newUser: User) => {
    if (isMobile) {
      setOpen(true)
    }
    setShowInvitationForm(false)
    return fetchUsers()
  }

  const onPaginationChange = (page: number, pageSize?: number) => {
    fetchUsers(page)
  }

  const renderDriverInfoText = () => {
    let temp = undefined
    if (tenants && tenants.length === 1) {
      temp = tenants[0].displayName || tenants[0].name
    } else if (tenants && tenants.length > 1) {
      temp = `${tenants.length} ${tenantsTxt}`
    }
    return `${driverInfoTxt} ${temp}`
  }

  const handleModifyBtn = (user: AugmentedUser) => {
    setSelectedUser(user)
    setEditUserNameForm(true)
  }

  const handleAddUser = () => {
    let currentAugmentedUserTemp = currentUser && toAugmentedUser(currentUser)
    setCurrentAugmentedUser(currentAugmentedUserTemp)
    // setAddUserToTenant(currentAugmentedUserTemp)
    setShowInvitationForm(true)
  }

  const renderDriverCheckbox = (user: AugmentedUser, tenants: TenantRef[]): boolean => {
    const { accesses } = user
    let driver = false
    if (isDesktop) {
      accesses.map((access) => {
        if (access.resourceId === tenants[0].id) {
          return access.permissions.map((permission) => {
            if (permission === 'tenant.driver') return (driver = true)
            return (driver = false)
          })
        }
        return driver
      })
    }
    return driver
  }

  const renderAccess = (user: AugmentedUser) => {
    const { tenantPermission } = user
    const permissions = Object.values(tenantPermission)

    let access: DashboardAccessType | 'Multi' | undefined
    if (tenants.length === 1) {
      access = permissions
        .filter((permission) => permission.id === tenants[0].id)
        .map((permission) => permission.access)[0]
    }
    if (access === undefined) {
      access = 'none'
    }

    if (access === 'none') {
      return <div>None</div>
    } else if (access === 'manager') {
      return <div>Manager</div>
    } else if (access === 'viewer') {
      return <div>Viewer</div>
    } else if (access === 'Multi') {
      return <div>Multi</div>
    }
  }

  const columns: any = [
    {
      title: idText,
      dataIndex: 'id',
    },
    {
      title: emailTxt,
      dataIndex: 'email',
    },
    {
      title: userText,
      render: (user: User) => {
        return user.name
      },
    },
  ]

  if (hasTenantScope && tenants && tenants.length === 1) {
    columns.push(
      {
        title: (
          <>
            {driverTxt}
            <Tooltip
              overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
              placement="top"
              title={renderDriverInfoText()}
            >
              <DriverSvgSpacing>{greyInfoSVG}</DriverSvgSpacing>
            </Tooltip>
          </>
        ),
        key: 'driver',
        render: (user: AugmentedUser) => {
          if (!tenantScope) {
            return <></>
          }
          const isDriver = renderDriverCheckbox(user, tenants)

          if (isDriver) {
            return <>{accessText}</>
          } else {
            return <div style={{ color: '#7D838A' }}>{noAccessText}</div>
          }
        },
      },
      {
        title: <div>{roleTxt}</div>,
        key: 'tenantAccess',
        render: (user: AugmentedUser) => {
          return renderAccess(user)
        },
      },
      {
        title: '',
        key: 'action',
        render: (user: AugmentedUser) => (
          <EditButtonWrapper>
            <ButtonModal type="link" icon={editSVG} click={() => handleModifyBtn(user)} />
          </EditButtonWrapper>
        ),
      },
    )
  } else {
    if (currentUser?.role === 'admin' || currentUser?.role === 'user') {
      columns.push({
        title: '',
        key: 'action',
        render: (user: AugmentedUser) => (
          <EditButtonWrapper>
            <ButtonModal type="link" icon={editSVG} click={() => handleModifyBtn(user)} />
          </EditButtonWrapper>
        ),
      })
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTerm(e.target.value)
  }

  const handleSubscriptionEmailChange = (user: UserWallet | undefined) => setSubscriptionEmailSearch(user)

  const onSubmitSubscriptionSearch = () => setSearchSubscriptions(!searchSubscriptions)

  const handleTabOptions = () => {
    switch (tab) {
      case 'overview':
        return !responsiveHeaderTitleTabletAndLaptop ? (
          <Row>
            <div className="user-selector">
              <MultiTenantStyling>
                <MultiTenantSelector
                  longerWidth={true}
                  isCombineRight
                  noChangeOnFocus={true}
                  onOptionsChange={(tenants) => setTenants(tenants)}
                  onClear={() => setTenants([])}
                />
              </MultiTenantStyling>
              <LineBreak />
              <UserSelectorStyling>
                <SearchButtonContainer>
                  <NoIconSearch
                    svg={<></>}
                    placeholder={emailTxt}
                    onChange={handleEmailChange}
                    onPressEnter={fetchUsers}
                  />
                  <SearchButtonWrapper>
                    <ButtonIcon
                      icon={magnifyBlackSVG}
                      loading={loading}
                      disabled={loading}
                      fetchData={fetchUsers}
                      withinSearchBar
                    />
                  </SearchButtonWrapper>
                </SearchButtonContainer>
              </UserSelectorStyling>
            </div>
            <Space>
              <InviteButtonWrapper>
                <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
              </InviteButtonWrapper>
            </Space>
          </Row>
        ) : (
          <></>
        )
      case 'plans':
        return (
          <TabsButtonWrapper className="plans-button">
            <ButtonModal
              size="large"
              click={() => setShowCreatePlanForm(true)}
              icon={plusSVG}
              text={createPlanText}
              type="primary"
            />
          </TabsButtonWrapper>
        )
      case 'subscriptions':
        return (
          <TabsButtonWrapper className="subscriptions-header" isLaptop={isLaptop || isDesktop}>
            <div style={{ display: 'flex' }}>
              <UserSelectorNoTagRedesign
                onUserSelected={handleSubscriptionEmailChange}
                onSubmit={onSubmitSubscriptionSearch}
                dropdownClassName={'not-in-modal-selctor'}
              />
            </div>
            {isDesktop || isLaptop ? (
              <ButtonModal
                size="large"
                click={() => setShowInviteForm(true)}
                icon={plusSVG}
                text={subscribeUserText}
                type="primary"
              />
            ) : (
              <InviteButtonCircleWrapper>
                <ButtonIconCircle
                  icon={plusSVG}
                  disabled={false}
                  fetchData={() => setShowInviteForm(true)}
                  loading={false}
                  size="large"
                />
              </InviteButtonCircleWrapper>
            )}
          </TabsButtonWrapper>
        )
      default:
        return null
    }
  }

  const renderTitle = () => {
    if (currentUser?.role !== 'admin') {
      return (
        <Row
          gutter={[8, 8]}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#fafafa',
            padding: '20px 4px 21px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <MultiTenantStyling>
              <MultiTenantSelector
                longerWidth={true}
                isCombineRight
                noChangeOnFocus={true}
                onOptionsChange={(tenants) => setTenants(tenants)}
                onClear={() => setTenants([])}
                dropdownClassName={'not-in-modal-selector'}
              />
            </MultiTenantStyling>
            <LineBreak />
            <UserSelectorStyling>
              <SearchButtonContainer>
                <NoIconSearch
                  svg={<></>}
                  placeholder={emailTxt}
                  onChange={handleEmailChange}
                  onPressEnter={fetchUsers}
                />
                <SearchButtonWrapper>
                  <ButtonIcon
                    icon={magnifyBlackSVG}
                    loading={loading}
                    disabled={loading}
                    fetchData={fetchUsers}
                    withinSearchBar
                  />
                </SearchButtonWrapper>
              </SearchButtonContainer>
            </UserSelectorStyling>
          </div>
          <Space>
            <InviteButtonWrapper>
              <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
            </InviteButtonWrapper>
          </Space>
        </Row>
      )
    }
  }

  const renderColumns = (): any => {
    let currentAugmentedUser = currentUser && toAugmentedUser(currentUser)
    const tenantAccesses =
      currentAugmentedUser?.tenantPermission && Object.values(currentAugmentedUser?.tenantPermission)
    const isManager = tenantAccesses?.some((item) => item.access === 'manager')

    if ((currentAugmentedUser?.isAdmin || isManager) && !term) {
      return columns
        .filter((col: any) => col.key !== 'manageraddusertotenants')
        .filter((col: any) => col.key !== 'addusertotenants')
    }
    return term ? columns : columns.filter((col: any) => col.key !== 'addusertotenants')
  }

  const renderTitleMobile = () => {
    return (
      <>
        <UserTitleMobileWrapper isAdmin={currentUser?.role === 'admin'}>
          <MultiTenantMobileStyling>
            <MultiTenantSelector
              longerWidth={true}
              isCombineRight
              noChangeOnFocus={true}
              onOptionsChange={(tenants) => setTenants(tenants)}
              onClear={() => setTenants([])}
              dropdownClassName={'not-in-modal-selector'}
            />
          </MultiTenantMobileStyling>
        </UserTitleMobileWrapper>
        <div style={{ display: 'flex' }}>
          <MultiTenantMobileStyling>
            <NoIconSearch svg={<></>} placeholder={emailTxt} onChange={handleEmailChange} onPressEnter={fetchUsers} />
          </MultiTenantMobileStyling>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <Button
            style={{ width: `${responsiveSearchButtonForMobile ? '80%' : '90%'}` }}
            type="primary"
            onClick={() => {
              fetchUsers()
            }}
          >
            {magnifyBlackSVG}
            <ButtonTextSpacing>
              <span className="paragraph-02-regular">SEARCH</span>
            </ButtonTextSpacing>
          </Button>
          <InviteButtonCircleWrapper>
            <ButtonIconCircle icon={plusSVG} fetchData={handleAddUser} disabled={false} loading={false} size="large" />
          </InviteButtonCircleWrapper>
        </div>
      </>
    )
  }

  const renderTitleTablet = () => {
    let padding = ''
    if (currentUser?.role === 'admin') {
      padding = '0px 4px 21px'
    } else {
      padding = '20px 4px 21px'
    }

    return (
      <Row
        gutter={[8, 8]}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fafafa',
          padding: padding,
        }}
      >
        <div style={{ display: 'flex' }}>
          <MultiTenantStyling>
            <MultiTenantSelector
              longerWidth={true}
              isCombineRight
              noChangeOnFocus={true}
              onOptionsChange={(tenants) => setTenants(tenants)}
              onClear={() => setTenants([])}
            />
          </MultiTenantStyling>
          {responsiveLineBreak && <LineBreak isAdmin={currentUser?.role === 'admin'} />}
          <UserSelectorStyling tablet={true}>
            <SearchButtonContainer>
              <NoIconSearch svg={<></>} placeholder={emailTxt} onChange={handleEmailChange} onPressEnter={fetchUsers} />
              <SearchButtonWrapper>
                <ButtonIcon
                  icon={magnifyBlackSVG}
                  loading={loading}
                  disabled={loading}
                  fetchData={fetchUsers}
                  withinSearchBar
                />
              </SearchButtonWrapper>
            </SearchButtonContainer>
          </UserSelectorStyling>
        </div>
        {showFullInviteButtonTabletAndLaptop || showFullInviteButtonMobileAndTablet ? (
          <>
            <InviteButtonWrapper>
              <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
            </InviteButtonWrapper>
          </>
        ) : (
          <InviteButtonCircleWrapper>
            <ButtonIconCircle icon={plusSVG} fetchData={handleAddUser} disabled={false} loading={false} size="large" />
          </InviteButtonCircleWrapper>
        )}
      </Row>
    )
  }

  const usersTable = (
    <Row>
      <Col span={24}>
        <AlertError error={error} />
        <Col>
          {(isDesktop || isLaptop) && (
            <TableStyling usersPage>
              <TableWrapper userIsAdmin={currentUser?.role === 'admin'}>
                <ResponsiveTable
                  title={responsiveHeaderTitleTabletAndLaptop ? renderTitleTablet : renderTitle}
                  loading={loading}
                  size="small"
                  pagination={{
                    position: ['bottomCenter'],
                    total: pagination?.totalEntries,
                    current: pagination?.currentPage,
                    pageSize: pagination?.perPage,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                  }}
                  dataSource={users}
                  columns={renderColumns()}
                  rowKey="id"
                />
              </TableWrapper>
            </TableStyling>
          )}
          {isTablet && (
            <>
              {showCollapsedTable ? (
                <>
                  {renderTitleMobile()}
                  <UsersCollapse
                    loading={false}
                    users={users}
                    pagination={pagination}
                    onPaginationChange={onPaginationChange}
                    tenants={tenants}
                    setEditUserNameForm={setEditUserNameForm}
                    setSelectedUser={setSelectedUser}
                  />
                </>
              ) : (
                <TableStyling usersPage>
                  <TableWrapper userIsAdmin={currentUser?.role === 'admin'}>
                    <ResponsiveTable
                      title={showFullInviteButtonMobileAndTablet ? renderTitleMobile : renderTitleTablet}
                      loading={loading}
                      size="small"
                      pagination={{
                        position: ['bottomCenter'],
                        total: pagination?.totalEntries,
                        current: pagination?.currentPage,
                        pageSize: pagination?.perPage,
                        showSizeChanger: false,
                        onChange: onPaginationChange,
                      }}
                      dataSource={users}
                      columns={renderColumns()}
                      rowKey="id"
                    />
                  </TableWrapper>
                </TableStyling>
              )}
            </>
          )}
          {isMobile && (
            <>
              {renderTitleMobile()}
              <UsersCollapse
                loading={false}
                users={users}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                tenants={tenants}
                setEditUserNameForm={setEditUserNameForm}
                setSelectedUser={setSelectedUser}
              />
            </>
          )}
        </Col>
      </Col>
    </Row>
  )

  const handleUserEdit = () => {
    setEditUserNameForm(false)
    return fetchUsers()
  }

  return (
    <>
      <>
        <CustomBox isLaptop={isLaptop || isDesktop} showMobileHeader={showCollapsedTable}>
          {currentUser?.role === 'admin' ? (
            <TabsWrapper>
              <Tabs
                className="tabs-styling"
                defaultActiveKey="overview"
                onChange={(key) => setTab(key)}
                tabBarExtraContent={handleTabOptions()}
              >
                <Tabs.TabPane key="overview" tab={<span className="regular-cap">{overviewText}</span>}>
                  {usersTable}
                </Tabs.TabPane>
                <Tabs.TabPane key="plans" tab={<span className="regular-cap">{plansText}</span>}>
                  <PlansRedesign createPlanForm={createPlanForm} onCancel={() => setShowCreatePlanForm(false)} />
                </Tabs.TabPane>
                <Tabs.TabPane key="subscriptions" tab={<span className="regular-cap">{subscriptionsText}</span>}>
                  <SubscriptionsRedesign
                    searchUser={subscriptionEmailSearch}
                    searchSubscriptions={searchSubscriptions}
                    onUserInvited={() => setShowInviteForm(false)}
                    showInviteForm={showInviteForm}
                  />
                </Tabs.TabPane>
              </Tabs>
            </TabsWrapper>
          ) : (
            usersTable
          )}
        </CustomBox>
      </>
      {showInvitationForm && currentAugmentedUser && (
        <UserInvitationRedesign
          open={open}
          setOpen={setOpen}
          onCancel={(e: React.FormEvent<EventTarget>) => setShowInvitationForm(false)}
          onUserInvited={handleUserInvited}
        />
      )}

      {selectedUser && showEditUserNameForm && currentAugmentedUser && (
        <UserEditForm
          onCancel={() => setEditUserNameForm(false)}
          onUserEdit={handleUserEdit}
          selectedUser={selectedUser}
          user={currentAugmentedUser}
        />
      )}
    </>
  )
}
