import { Button, Col, PageHeader, Row, Space, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'

import { AlertError } from '../../components/error'

import { LocationSelector } from '../../components/redesign/location-selector'
import { MultiTenantSelector } from '../../components/redesign/multiple-tenant-selector'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { useAppState } from '../../state'

import {
  HistoricalDataSectionWrapper,
  HomePageContainer,
  RealTimeDataSectionWrapper,
  SectionGap,
} from './ContainerStyles/homepageStyles'
import {
  FetchTransactions,
  FetchTransactionsSummary,
  TransactionFilter,
} from '../../services/data-provider/transactionv2'
import { TransactionResponsev2, TransactionSummary } from '../../models/http'
import {
  ChargerProfile,
  DailyProfile,
  StationActivation,
  Transactionv2,
  TrxnOverview,
  TrxSummary,
} from '../../models/transaction'
import { ChargerInfo, ChargerStationInfo, ConnectorInfo } from '../../models/chargerv2'

import { getSession } from '../../state/session-store'
import { log } from '../../logger'
import configProvider from '../../config'
import OperationOverview from './components/OperationOverview'
import MetricsSummary from './components/metrics/MetricsSummary'
import { mockChargerInfo } from '../../mock/websocket/charger'
import { useDashboardTranslation } from '../../hooks/translation/useDashboardTranslation'
import StationOverviewSection from './section/station-overview-section'
import UtilizationInsightsSection from './section/utilization-insights-section'
import ConsumptionInsightsSection from './section/consumption-insights-section'
import StationActivationSection from './section/station-activation-section'
import { useMediaQuery } from 'react-responsive'
import Stamp from 'components/stamp'
import { useFormatMessage } from 'helpers/intl'
import { CustomDateRangePicker } from 'components/redesign/datepicker'
import { theme } from 'theme'
import { useCalendarTransactions } from 'hooks/translation/useCalendarTranslation'
import { MasterAccountRef } from 'models/master-account'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useMasterAccountSelector } from 'hooks/useMasterAccountSelector'

highchartsMore(Highcharts)
solidGauge(Highcharts)

export const DashboardBasePageRedesign: React.FC = () => {
  const { selectedTenant } = useAppState()
  const { calendarRanges } = useCalendarTransactions()
  const [startDate, setStartDate] = useState(moment().subtract(6, 'd'))
  const [endDate, setEndDate] = useState(moment(moment()))

  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(true)
  const [wsLoading, setWsLoading] = useState(false)

  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [transactionsSummary, setTransactionsSummary] = useState<TransactionSummary>()
  const [utilizationInsightChargerProfiles, setUtilizationInsightChargerProfiles] = useState<ChargerProfile[]>()
  const [stationActivation, setStationActivation] = useState<StationActivation>()
  const [trxProfileSummary, setTrxProfileSummary] = useState<TrxSummary>()
  const [trnxOverview, setTrnxOverview] = useState<TrxnOverview[]>()
  const [dailyProfiles, setDailyProfiles] = useState<DailyProfile[]>()
  const [chargerInfo, setChargerInfo] = useState<ChargerInfo>()
  const [chargerStationInfo, setChargerStationInfo] = useState<ChargerStationInfo>()
  const [connectorInfo, setConnectorInfo] = useState<ConnectorInfo>()
  const [utlizationInsightTabKey, setUtlizationInsightTabKey] = useState('All')
  const [utlizationInsightGaugeTabKey, setUtlizationInsightGaugeTabKey] = useState('All')
  const [consumptionInsightLoadProfileTabKey, setConsumptionInsightLoadProfileTabKey] = useState('All')
  const [operationOverviewTabKey, setOperationOverviewTabKey] = useState('Transactions')
  const [reveneChartVisible, setRevenueChartVisible] = useState(true)
  const [transactionsChartVisible, setTransactionsChartVisible] = useState(false)
  const [energyChartVisible, setEnergyChartVisible] = useState(false)
  const [utilizationAllVisible, setUtilizationAllVisible] = useState(true)
  const [utilizationWeekdayVisible, setUtilizationWeekdayVisible] = useState(false)
  const [utilizationWeekendVisible, setUtilizationWeekendVisible] = useState(false)
  const [consumptionAllVisible, setConsumptionAllVisible] = useState(true)
  const [consumptionWeekdayVisible, setConsumptionWeekdayVisible] = useState(false)
  const [consumptionWeekendVisible, setConsumptionWeekendVisible] = useState(false)

  const [chargingDurationAllVisible, setChargingDurationAllVisible] = useState(true)
  const [chargingDurationLevel2Visible, setChargingDurationLevel2Visible] = useState(false)
  const [chargingDurationDCFCVisible, setChargingDurationDCFCVisible] = useState(false)

  const [stationActivationDataEmpty, setStationActivationDataEmpty] = useState(false)
  const [averageDailyLoadProfileEmpty, setAverageDailyLoadProfileEmpty] = useState(false)
  const [utilizationProfileEmpty, setUtilizationProfileEmpty] = useState(false)
  const [utilizationGauageEmpty, setUtilizationGaugeEmpty] = useState(false)

  const [ws, setWebsocket] = useState<WebSocket>()
  const [updateDict, setUpdateDict] = useState(`update=1`)
  const [tenantsUpdateStr, setTenantsUpdateStr] = useState('')
  const [locationUpdateStr, setLocationUpdateStr] = useState('')
  const [masterAccountUpdateStr, setMasterAccountUpdateStr] = useState('')

  const [timeZone, setTimeZone] = useState('')

  const [wsConnected, setWsConnected] = useState(false)

  const [locationDictStr, setLocationDictStr] = useState('')
  const [tenantDictStr, setTenantDictStr] = useState('')
  const [masterAccountDictStr, setMasterAccountDictStr] = useState('')
  const wsURL = useRef(configProvider.config.dashboardWsDomain)

  const [filter, setFilter] = useState<TransactionFilter>({
    page: 1,
    perPage: 10,
    defaultTenant: selectedTenant,
  })

  const [transactionResponse, setTransactionResponse] = useState<TransactionResponsev2>()
  const [transactions, setTransactions] = useState<Transactionv2[]>()
  const [masterAccount, setMasterAccount] = useState<MasterAccountRef>()

  const { stationOverviewText, operationOverviewText } = useDashboardTranslation()
  const { currentUser } = useAppState()
  const { accountNameText } = useSelectorTranslation()
  const { options, allMasterAccounts, renderMasterAccountOption } = useMasterAccountSelector(setMasterAccount)
  const isAdmin = currentUser?.role === 'admin'

  document.querySelector('body')?.classList.add('redesignActive')
  const isResponsiveStyling = useMediaQuery({ minWidth: 0, maxWidth: 649 })

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const handleLocation = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
    setFilter({ ...filter, country: chosenCountries.join('%'), province: chosenProvinces.join('%') })
  }

  const handleTenantSelection = (tenants: TenantRef[]) => {
    // update tenants and remove any duplicates
    setTenants(
      tenants.filter((obj, index) => {
        return index === tenants.findIndex((o) => obj.id === o.id)
      }),
    )
    setFilter({ ...filter, tenants: tenants })
  }

  const handleMasterAccountSelection = (
    value: any,
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    const findMasterAccount = allMasterAccounts?.find((p) => p.name === value)
    setMasterAccount(findMasterAccount)
    setSelectedOptions(value)
    onOptionsChange(value)
    setFilter({ ...filter, masterAccountId: findMasterAccount?.id })
  }

  useEffect(() => {
    fetchTransactionSummary()
  }, [filter])

  useEffect(() => {
    if (!wsConnected) {
      setWsLoading(true)
      startWebSocket()
    }
    return () => {
      ws && ws.send('update=0Tclose=1')
    }
  }, [wsConnected])

  const fetchTransactionSummary = () => {
    setLoading(true)
    setError(undefined)
    const controller = new AbortController()

    Promise.all([
      FetchTransactionsSummary(filter, startDate, endDate, controller).then((resp) => {
        setTransactionsSummary(resp)
      }),
      FetchTransactions(filter, startDate, endDate, controller).then((resp) => {
        setTransactionResponse(resp)
      }),
    ])
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    transactionsSummary && setUtilizationInsightChargerProfiles(transactionsSummary.chargers)
    transactionsSummary && setStationActivation(transactionsSummary.summary.stationActivation)
    transactionsSummary && setTrxProfileSummary(transactionsSummary.summary)
    transactionsSummary && setTrnxOverview(transactionsSummary.txnOverview)
    transactionsSummary && setDailyProfiles(transactionsSummary.dailyProfiles)
    transactionsSummary && transactionsSummary.timezone
      ? setTimeZone(transactionsSummary.timezone)
      : setTimeZone('local')
    transactionResponse && setTransactions(transactionResponse.transactions)

    setUtlizationInsightGaugeTabKey(utlizationInsightGaugeTabKey)
    setOperationOverviewTabKey(operationOverviewTabKey)
    setUtlizationInsightTabKey(utlizationInsightTabKey)
    setConsumptionInsightLoadProfileTabKey(consumptionInsightLoadProfileTabKey)

    transactionsSummary &&
      setStationActivationDataEmpty(
        Object.values(transactionsSummary.summary.stationActivation).every((obj) => obj === 0),
      )
    handleTabChangeUtilizationInsightTab(utlizationInsightTabKey)
    handleTabChangeConsumptionInsightTab(consumptionInsightLoadProfileTabKey)
    handleTabUtilizationInsightChargingDurationTab(utlizationInsightGaugeTabKey)
    handleTabChangeOperationOverviewTab(operationOverviewTabKey)
  }, [transactionsSummary, transactionResponse])

  const startWebSocket = () => {
    if (configProvider.config.mockEnabled || configProvider.config.mockWebSocket) {
      setWsLoading(false)
      setChargerInfo(mockChargerInfo)
      return
    }
    const session = getSession()

    if (!session) throw new Error('not logged in')

    log('---before creating ws :', wsURL.current)

    const ws = new WebSocket(encodeURI(wsURL.current), [`${session.token}`, `${session.id}`])

    setWebsocket(ws)

    ws.addEventListener('open', (event) => {
      //console.log('WebSocket open')
      log('WebSocket open')
    })

    ws.addEventListener('message', (event) => {
      log('Received message ', event.data)
    })

    ws.addEventListener('close', (event) => {
      log('WebSocket connection closed')
    })

    ws.addEventListener('error', (event) => {
      log('WebSocket connection error:', event)
    })

    // console.log('-------------socket----------------------')

    ws.onopen = function (e) {
      log('[open] Connection established')
      log('Sending to server')
      setWsConnected(true)
      // ws.send(dict)
    }

    ws.onmessage = function (event) {
      setWsLoading(false)
      // console.log(`[message] Data received from server: ${event.data}`)
      log(`[message] Data received from server: ${event.data}`)
      // console.log('---resp----')
      // console.log(event.data)
      //ws.send('1')
      setChargerInfo(JSON.parse(event.data))
      event.data.chargers && setChargerStationInfo(JSON.parse(event.data.chargers))
    }

    ws.onclose = function (event) {
      if (event.wasClean) {
        //console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
        log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        //console.log('[close] Connection died')
        log('[close] Connection died')
      }
      log('wsURL onClose:', wsURL.current)
      // log('closeFlag.current', !closeFlag.current)
      // setTimeout(() => {
      //   startWebSocket()
      // }, 1000)
    }
  }

  useEffect(() => {
    // console.log('----i am triggered----')

    tenants.length > 0
      ? setTenantsUpdateStr(`Ttenants_id=${[...tenants.map((t) => t.id)].join('%')}`)
      : setTenantsUpdateStr('')

    selectedCountries.length > 0 && selectedProvinces.length > 0
      ? setLocationUpdateStr(
          `Tcountry=${[...selectedCountries.map((country) => country)].join('%')}Tprovince=${[
            ...selectedProvinces.map((province) => province),
          ].join('%')}`,
        )
      : setLocationUpdateStr('')

    masterAccount ? setMasterAccountUpdateStr(`Tmaster_account_ids=${masterAccount.id}`) : setMasterAccountUpdateStr('')

    tenants.length > 0
      ? setTenantDictStr(`tenants_id=${[...tenants.map((t) => t.id)].join('%')}`)
      : setTenantDictStr('')

    selectedCountries.length > 0 && selectedProvinces.length > 0
      ? setLocationDictStr(
          `country=${[...selectedCountries.map((country) => country)].join('%')}&province=${[
            ...selectedProvinces.map((province) => province),
          ].join('%')}`,
        )
      : setLocationDictStr('')

    masterAccount ? setMasterAccountDictStr(`master_account_ids=${masterAccount.id}`) : setMasterAccountDictStr('')
  }, [tenants, selectedCountries, selectedProvinces, masterAccount])

  useEffect(() => {
    setUpdateDict(`update=1${tenantsUpdateStr}${locationUpdateStr}${masterAccountUpdateStr}`)
  }, [tenantsUpdateStr, locationUpdateStr, masterAccountUpdateStr])

  useEffect(() => {
    log('updateDict: ', updateDict)
    if (ws) {
      ws.send(updateDict)
    }
  }, [updateDict])

  useEffect(() => {
    const parts = [tenantDictStr, locationDictStr, masterAccountDictStr].filter((part) => part && part.length > 0)
    if (parts.length > 0) {
      wsURL.current = `${configProvider.config.dashboardWsDomain}${parts.join('&')}`
    }
  }, [tenantDictStr, locationDictStr, masterAccountDictStr])

  useEffect(() => {
    chargerInfo && setChargerStationInfo(chargerInfo.chargers)
    chargerInfo && setConnectorInfo(chargerInfo.connectors)
  }, [chargerInfo])

  const handleTabChangeOperationOverviewTab = (activeKey: string) => {
    switch (activeKey) {
      case 'Revenue':
        setRevenueChartVisible(true)
        setTransactionsChartVisible(false)
        setEnergyChartVisible(false)
        setOperationOverviewTabKey('Revenue')
        break
      case 'Transactions':
        setRevenueChartVisible(false)
        setTransactionsChartVisible(true)
        setEnergyChartVisible(false)
        setOperationOverviewTabKey('Transactions')
        break
      case 'Energy':
        setRevenueChartVisible(false)
        setTransactionsChartVisible(false)
        setEnergyChartVisible(true)
        setOperationOverviewTabKey('Energy')
        break
    }
  }

  const handleTabChangeUtilizationInsightTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setUtilizationAllVisible(true)
        setUtilizationWeekdayVisible(false)
        setUtilizationWeekendVisible(false)
        setUtlizationInsightTabKey('All')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeek).every((entry) => entry === 0),
          )
        break
      case 'Weekday':
        setUtilizationAllVisible(false)
        setUtilizationWeekdayVisible(true)
        setUtilizationWeekendVisible(false)
        setUtlizationInsightTabKey('Weekday')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeekday).every((entry) => entry === 0),
          )
        break
      case 'Weekend':
        setUtilizationAllVisible(false)
        setUtilizationWeekdayVisible(false)
        setUtilizationWeekendVisible(true)
        setUtlizationInsightTabKey('Weekend')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeekend).every((entry) => entry === 0),
          )
        break
    }
  }

  const handleTabChangeConsumptionInsightTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setConsumptionAllVisible(true)
        setConsumptionWeekdayVisible(false)
        setConsumptionWeekendVisible(false)
        setConsumptionInsightLoadProfileTabKey('All')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeek).every((entry) => entry === 0),
          )
        break
      case 'Weekday':
        setConsumptionAllVisible(false)
        setConsumptionWeekdayVisible(true)
        setConsumptionWeekendVisible(false)
        setConsumptionInsightLoadProfileTabKey('Weekday')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeekday).every((entry) => entry === 0),
          )
        break
      case 'Weekend':
        setConsumptionAllVisible(false)
        setConsumptionWeekdayVisible(false)
        setConsumptionWeekendVisible(true)
        setConsumptionInsightLoadProfileTabKey('Weekend')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeekend).every((entry) => entry === 0),
          )
        break
    }
  }

  const handleTabUtilizationInsightChargingDurationTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setChargingDurationAllVisible(true)
        setChargingDurationLevel2Visible(false)
        setChargingDurationDCFCVisible(false)
        setUtlizationInsightGaugeTabKey('All')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayDuration === 0 &&
              transactionsSummary.summary.chargerAverageDayPlugin === 0,
          )
        break
      case 'Level2':
        setChargingDurationAllVisible(false)
        setChargingDurationLevel2Visible(true)
        setChargingDurationDCFCVisible(false)
        setUtlizationInsightGaugeTabKey('Level2')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayLevel2Plugin === 0 &&
              transactionsSummary.summary.chargerAverageDayLevel2Duration === 0,
          )
        break
      case 'DCFC':
        setChargingDurationAllVisible(false)
        setChargingDurationLevel2Visible(false)
        setChargingDurationDCFCVisible(true)
        setUtlizationInsightGaugeTabKey('DCFC')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayDcfcDuration === 0 &&
              transactionsSummary.summary.chargerAverageDayDcfcPlugin === 0,
          )
        break
    }
  }

  const pageHeaderTitle = (
    <Space>
      <h5 className="section-sub-header" style={{ marginBottom: 0, paddingTop: '8px' }}>
        {stationOverviewText}
      </h5>
      {configProvider.config.mockWebSocket ? <Stamp /> : null}
    </Space>
  )

  const operationOverviewTitle = (
    <h5 className="section-sub-header" style={{ marginBottom: '15px', paddingTop: '10px' }}>
      {operationOverviewText}
    </h5>
  )

  const renderMasterAccountSelector = () => (
    <ReusableSelector
      showSearch={true}
      onClear={() => setMasterAccount(undefined)}
      isSingle={true}
      showMagnifySVG={true}
      maxTagCount={0}
      isDebounceFetcher={false}
      handlePlaceholder={undefined}
      debounceFetcher={undefined}
      placeholder={accountNameText}
      dropDownList={options}
      selectAllOptions={true}
      showArrow={false}
      stopClearSearchValue={true}
      handleOptionChange={handleMasterAccountSelection}
      renderOption={renderMasterAccountOption}
    />
  )

  const pageHeaderExtra = isAdmin ? (
    <Row gutter={[8, 8]}>
      <Col xs={24} sm={8}>
        <LocationSelector onSearch={debounce(handleLocation, 1000)} loading={loading} />
      </Col>
      <Col xs={24} sm={8}>
        {renderMasterAccountSelector()}
      </Col>
      <Col xs={24} sm={8}>
        <MultiTenantSelector
          onOptionsChange={debounce(handleTenantSelection, 1000)}
          onClear={() => setTenants([])}
          longerWidth={false}
        />
      </Col>
    </Row>
  ) : (
    <Row gutter={[0, 0]}>
      <Col span={24}>
        <MultiTenantSelector
          onOptionsChange={debounce(handleTenantSelection, 1000)}
          onClear={() => setTenants([])}
          longerWidth={false}
        />
      </Col>
    </Row>
  )

  const historicalDataSectionExtra = (
    <>
      <Col xs={24} sm={12} md={2} lg={2} xl={2} xxl={2}>
        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChangeDate={onChangeDate}
          calendarRanges={calendarRanges}
        />
      </Col>
      <Col xs={24} sm={7} md={7} lg={7} xl={7} xxl={7}>
        <Button
          type="default"
          onClick={fetchTransactionSummary}
          style={{ marginRight: '5px', width: isResponsiveStyling ? '100%' : '' }}
        >
          <span style={{ fontFamily: theme.fontFamily.lightFontFamily }} className="regular-cap">
            {useFormatMessage('dashboard.homePage.search', 'Search')}
          </span>
        </Button>
      </Col>
    </>
  )

  return (
    <>
      <HomePageContainer isResponsiveStyling={isResponsiveStyling}>
        <AlertError error={error} />
        <RealTimeDataSectionWrapper>
          <PageHeader title={pageHeaderTitle} extra={pageHeaderExtra} />
          <StationOverviewSection
            wsLoading={wsLoading}
            chargerInfo={chargerInfo}
            connectorInfo={connectorInfo}
            chargerStationInfo={chargerStationInfo}
            tenants={tenants}
            masterAccount={masterAccount}
          />
        </RealTimeDataSectionWrapper>
        <SectionGap />
        <HistoricalDataSectionWrapper>
          <Spin spinning={loading}>
            <PageHeader title={operationOverviewTitle} extra={historicalDataSectionExtra} />
            <OperationOverview
              loading={loading}
              startDate={startDate}
              endDate={endDate}
              trnxOverview={trnxOverview}
              operationOverviewTabKey={operationOverviewTabKey}
              reveneChartVisible={reveneChartVisible}
              energyChartVisible={energyChartVisible}
              transactionsChartVisible={transactionsChartVisible}
              onHandleTabChangeOperationOverviewTab={handleTabChangeOperationOverviewTab}
            />
            <MetricsSummary loading={loading} trxProfileSummary={trxProfileSummary} />
            <UtilizationInsightsSection
              utilizationProfileEmpty={utilizationProfileEmpty}
              utilizationInsightChargerProfiles={utilizationInsightChargerProfiles}
              handleTabChangeUtilizationInsightTab={handleTabChangeUtilizationInsightTab}
              utlizationInsightTabKey={utlizationInsightTabKey}
              utilizationAllVisible={utilizationAllVisible}
              timeZone={timeZone}
              utilizationWeekdayVisible={utilizationWeekdayVisible}
              utilizationWeekendVisible={utilizationWeekendVisible}
              trxProfileSummary={trxProfileSummary}
              utilizationGauageEmpty={utilizationGauageEmpty}
              handleTabUtilizationInsightChargingDurationTab={handleTabUtilizationInsightChargingDurationTab}
              utlizationInsightGaugeTabKey={utlizationInsightGaugeTabKey}
              chargingDurationAllVisible={chargingDurationAllVisible}
              chargingDurationLevel2Visible={chargingDurationLevel2Visible}
              chargingDurationDCFCVisible={chargingDurationDCFCVisible}
            />
            <ConsumptionInsightsSection
              loading={loading}
              timeZone={timeZone}
              trxProfileSummary={trxProfileSummary}
              handleTabChangeConsumptionInsightTab={handleTabChangeConsumptionInsightTab}
              averageDailyLoadProfileEmpty={averageDailyLoadProfileEmpty}
              consumptionInsightLoadProfileTabKey={consumptionInsightLoadProfileTabKey}
              dailyProfiles={dailyProfiles}
              consumptionAllVisible={consumptionAllVisible}
              consumptionWeekdayVisible={consumptionWeekdayVisible}
              consumptionWeekendVisible={consumptionWeekendVisible}
            />
            <StationActivationSection
              stationActivationDataEmpty={stationActivationDataEmpty}
              stationActivation={stationActivation}
              transactions={transactions}
            />
          </Spin>
        </HistoricalDataSectionWrapper>
      </HomePageContainer>
    </>
  )
}
