import { Checkbox, Form, Input, Radio, Space } from 'antd'
import EmailDomainInput from 'components/reusableSelector/emailDomainSelector'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { theme } from 'theme'

interface props {
  accessType: string
  driversChecked: boolean
  accessCodeChecked: boolean
  emailDomainChecked: boolean
  emailDomains: string[]
  handleAccessTypeChange: (e: any) => void
  setAccessCodeChecked: (accessCodeChecked: boolean) => void
  setEmailDomainChecked: (emailDomainChecked: boolean) => void
  setEmailDomains: (emailDomains: string[]) => void
}

export const AccessTypeSectionModal: React.FC<props> = ({
  accessType,
  driversChecked,
  accessCodeChecked,
  emailDomainChecked,
  emailDomains,
  handleAccessTypeChange,
  setAccessCodeChecked,
  setEmailDomainChecked,
  setEmailDomains,
}) => {
  const { accessTypeText, decommissionedOption1Right } = useListingBasicTrans()
  const {
    accessCodeText,
    restrictBasedOnText,
    noRestrictionsOnAccessText,
    unrestrictedText,
    restrictedAccessText,
    assignedDriversText,
    emailDomainText,
    driversText,
    usersGetAccessByAccessCodeText,
    usersGetAccessByEmailText,
  } = useAccessPlanTranslation()

  return (
    <div>
      <div className="heading-06-regular">
        {accessTypeText}
        <span style={{ color: 'red' }}>*</span>
      </div>
      <Form.Item name="accessType">
        <Radio.Group onChange={handleAccessTypeChange} value={accessType} style={{ width: '100%' }} buttonStyle="solid">
          <Space direction="vertical" size={0} style={{ width: '100%', gap: 'initial' }}>
            <div
              style={{
                padding: '12px 24px',
                borderRadius: '8px',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: accessType === 'unrestricted' ? theme.colors.neonGreenShade : '',
                border:
                  accessType === 'unrestricted'
                    ? `1px solid ${theme.colors.primary}`
                    : `1px solid ${theme.colors.lightGrey}`,
              }}
            >
              <Radio value="unrestricted">
                <span className="paragraph-01-regular">{unrestrictedText}</span>
              </Radio>
              <div className="paragraph-02-regular" style={{ color: theme.colors.softBlackShade, marginLeft: '24px' }}>
                {noRestrictionsOnAccessText}
              </div>
            </div>

            <div
              style={{
                padding: '12px 24px',
                borderRadius: '8px',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomLeftRadius: accessType === 'restricted' ? '0px' : '8px',
                borderBottomRightRadius: accessType === 'restricted' ? '0px' : '8px',
                backgroundColor: accessType === 'restricted' ? theme.colors.neonGreenShade : '',
                border:
                  accessType === 'restricted'
                    ? `1px solid ${theme.colors.primary}`
                    : `1px solid ${theme.colors.lightGrey}`,
              }}
            >
              <Radio value="restricted">
                <span className="paragraph-01-regular">{decommissionedOption1Right}</span>
              </Radio>
              <div className="paragraph-02-regular" style={{ color: theme.colors.softBlackShade, marginLeft: '24px' }}>
                {restrictedAccessText}
              </div>
            </div>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) => prevValues.accessType !== currentValues.accessType}
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              {({ getFieldValue }) =>
                getFieldValue('accessType') === 'restricted' && (
                  <div
                    style={{
                      background: theme.colors.ternary25,
                      width: '100%',
                      padding: '16px 48px',
                      borderRadius: '8px',
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '0px',
                      border: `1px solid ${theme.colors.lightGrey}`,
                    }}
                  >
                    <div className="restricted-extras">
                      <div className="paragraph-01-regular">{restrictBasedOnText}*</div>
                      <Form.Item
                        name="restrictDrivers"
                        valuePropName="checked"
                        help={
                          <div
                            className="paragraph-02-regular"
                            style={{ color: theme.colors.softBlackShade, paddingLeft: '28px' }}
                          >
                            {assignedDriversText}
                          </div>
                        }
                      >
                        <Checkbox checked={driversChecked} disabled>
                          <div className="paragraph-01-regular" style={{ marginBottom: '4px' }}>
                            {driversText}
                          </div>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="accessCodeChecked"
                        help={
                          <div
                            className="paragraph-02-regular"
                            style={{ color: theme.colors.softBlackShade, paddingLeft: '28px' }}
                          >
                            {usersGetAccessByAccessCodeText}
                          </div>
                        }
                        style={{ marginBottom: '0px' }}
                      >
                        <Checkbox onChange={() => setAccessCodeChecked(!accessCodeChecked)}>
                          <div className="paragraph-01-regular" style={{ marginBottom: '4px' }}>
                            {accessCodeText}
                          </div>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="accessCode" style={{ marginTop: '0px' }}>
                        <Input
                          placeholder="A12345"
                          disabled={!accessCodeChecked}
                          style={{ marginLeft: '22px', width: '96%' }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="emailDomainChecked"
                        help={
                          <div
                            className="paragraph-02-regular"
                            style={{ color: theme.colors.softBlackShade, paddingLeft: '28px' }}
                          >
                            {usersGetAccessByEmailText}
                          </div>
                        }
                        style={{ marginBottom: '0px' }}
                      >
                        <Checkbox onChange={() => setEmailDomainChecked(!emailDomainChecked)}>
                          <div className="paragraph-01-regular" style={{ marginBottom: '4px' }}>
                            {emailDomainText}
                          </div>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="emailDomain" style={{ marginTop: '0px' }}>
                        <div style={{ marginLeft: '22px', width: '96%' }}>
                          <EmailDomainInput
                            disabled={!emailDomainChecked}
                            emailDomains={emailDomains}
                            setEmailDomains={setEmailDomains}
                          />
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                )
              }
            </Form.Item>
          </Space>
        </Radio.Group>
      </Form.Item>
    </div>
  )
}
