import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { ChargerRef } from 'models/charger'
import { CreateAccessPlan } from 'services/data-provider/access-plan'
import { Tenant } from 'models/tenant'
import { GetChargersByTenants } from 'services/data-provider/charger'

// helper funciton to map rate arrays
const createRateArray = (rates: any[]) => rates.map((rate) => [rate.from * 60, rate.to * 60])

const initialCheckedState = {
  weekdayChecked: false,
  weekendChecked: false,
  mondayChecked: false,
  tuesdayChecked: false,
  wednesdayChecked: false,
  thursdayChecked: false,
  fridayChecked: false,
  saturdayChecked: false,
  sundayChecked: false,
}

export const useAccessPlan = (tenant: Tenant) => {
  const [form] = Form.useForm()
  const initialValues = { accessType: 'restricted', scheduleType: '24/7', restrictDrivers: true }
  const [accessType, setAccessType] = useState<string>('restricted')
  const [scheduleType, setScheduleType] = useState<string>('24/7')
  const [driversChecked, setDriversChecked] = useState<boolean>(true)
  const [accessCodeChecked, setAccessCodeChecked] = useState<boolean>(false)
  const [emailDomainChecked, setEmailDomainChecked] = useState<boolean>(false)
  const [emailDomains, setEmailDomains] = useState<string[]>([])
  const [checkedDays, setCheckedDays] = useState(initialCheckedState)
  const [selectedChargers, setSelectedChargers] = useState<ChargerRef[]>([])
  const [allChargers, setAllChargers] = useState<ChargerRef[]>([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    GetChargersByTenants([tenant]).then((resp) => setAllChargers(resp))
  }, [])

  useEffect(() => {
    const { accessPlanName } = form.getFieldsValue()
    setIsButtonDisabled(!(selectedChargers.length > 0 && accessPlanName))
  }, [selectedChargers])

  const handleDayCheckedChange = (day: string, isChecked: boolean) => {
    setCheckedDays((prev) => ({ ...prev, [day]: isChecked }))
  }

  const handleAccessTypeChange = (e: any) => {
    setAccessType(e.target.value)
  }

  const handleScheduleTypeChange = (e: any) => {
    setScheduleType(e.target.value)
  }

  const createSchedules = (values: any) => {
    if (values.scheduleType === 'weekdayWeekend') {
      const weekdayRateArr = 'weekdayRates' in values ? createRateArray(values.weekdayRates) : []
      const weekendRateArr = 'weekendRates' in values ? createRateArray(values.weekendRates) : []

      return {
        0: weekdayRateArr,
        1: weekdayRateArr,
        2: weekdayRateArr,
        3: weekdayRateArr,
        4: weekdayRateArr,
        5: weekendRateArr,
        6: weekendRateArr,
      }
    }

    if (values.scheduleTypes === 'customDays') {
      return {
        0: 'mondayRates' in values ? createRateArray(values.mondayRates) : [],
        1: 'tuesdayRates' in values ? createRateArray(values.tuesdayRates) : [],
        2: 'wednesdayRates' in values ? createRateArray(values.wednesdayRates) : [],
        3: 'thursdayRates' in values ? createRateArray(values.thursdayRates) : [],
        4: 'fridayRates' in values ? createRateArray(values.fridayRates) : [],
        5: 'saturdayRates' in values ? createRateArray(values.saturdayRates) : [],
        6: 'sundayRates' in values ? createRateArray(values.sundayRates) : [],
      }
    }
    return {}
  }

  const createAccessPlan = (values: any, setVisible: (visible: boolean) => void) => {
    const buildSchedule = createSchedules(values)
    const listingsArr: number[] = []
    selectedChargers.map((chargers) => listingsArr.push(chargers.id))

    const payload: any = {
      name: values.accessPlanName,
      tenant_id: tenant.id,
      listing_ids: listingsArr,
      access_type: values.accessType,
      has_operation_hours: values.scheduleType !== '24/7',
      enable_access_code: accessCodeChecked,
      enable_email_domains: emailDomainChecked,
      email_domains: emailDomains,
      access_code: values.accessCode,
      schedules: buildSchedule,
    }

    CreateAccessPlan(payload, Number(tenant.id))
      .then((resp) => {
        setVisible(false)
      })
      .catch((err) => console.log('err', err))
  }

  const validateInput = (input: string): string => {
    // Regular expression to remove unwanted characters
    const disallowedCharsRegex = /['",,*^/\\[\]+=:.()]/g
    let sanitizedInput = input.replace(disallowedCharsRegex, '')

    // Prevent links
    const linkRegex = /(https?:\/\/|www\.)/gi
    if (linkRegex.test(sanitizedInput)) {
      sanitizedInput = sanitizedInput.replace(linkRegex, '')
    }

    return sanitizedInput
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    // Check if all required fields are filled
    const values = form.getFieldsValue()
    const allFieldsFilled = values.accessPlanName && selectedChargers.length > 0

    // Disable or enable the button based on whether the fields are filled
    setIsButtonDisabled(!allFieldsFilled)

    const accessCode = changedValues.accessCode
    if (accessCode !== undefined) {
      const validatedValue = validateInput(accessCode)
      form.setFieldsValue({ accessCode: validatedValue })
    }
  }

  return {
    form,
    initialValues,
    accessCodeChecked,
    emailDomainChecked,
    emailDomains,
    accessType,
    driversChecked,
    scheduleType,
    checkedDays,
    allChargers,
    isButtonDisabled,
    setSelectedChargers,
    setAllChargers,
    setAccessCodeChecked,
    setEmailDomainChecked,
    setEmailDomains,
    handleDayCheckedChange,
    handleAccessTypeChange,
    handleScheduleTypeChange,
    handleValuesChange,
    createAccessPlan,
    setDriversChecked,
    setAccessType,
    setScheduleType,
  }
}
