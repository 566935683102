import { Button, Col, Row, Space } from 'antd'
import { useAppState } from 'state'

import { ReusableSelector } from 'components/reusableSelector/selector'

import { useSelector } from 'hooks/useSelector'

import { ParticipantsFilter } from 'models/filter'

import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

import { magnifyBlackSVG } from 'assets/svg/magnify'
import { ButtonIcon } from 'atom/button'
import { LineBreak } from 'atom/line-break'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { ListingRef } from 'models/listing'
import { PeakShavingProgram } from 'models/peak-shaving'
import { FindListings } from 'services/data-provider/listing'
import { GetAllPeakShavingPrograms } from 'services/data-provider/peak-shaving'
import { FindTenants } from 'services/data-provider/tenants'

interface props {
  loading: boolean
  filter: ParticipantsFilter
  dropdownClassName?: string
  onFilter: (filters: ParticipantsFilter) => void
  onFetch: () => void
}

const ParticipantsSelectors: React.FC<props> = ({ loading, filter, dropdownClassName, onFilter, onFetch }) => {
  const { IsDesktop, IsLaptop } = useAppState()

  const { searchText } = useGeneralTranslation()
  const { generalDebounceFetcher, renderMultiOptions, multiHandlePlaceholder, multiHandleChange } = useSelector()

  const { locationTextPlaceholder } = useSelectorTranslation()
  const { listingTitlesText } = useListingBasicTrans()
  const { programTabProgramSelectorText } = usePeakShavingTranslation()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const renderSitesSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      clearSearchAfterSelect
      onClear={() => onFilter({ ...filter, tenants: undefined })}
      placeholder={locationTextPlaceholder}
      dropDownList={[]}
      defaultValues={filter?.tenants}
      fieldForValue="combineName"
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      handlePlaceholder={multiHandlePlaceholder('combineName', locationTextPlaceholder)}
      debounceFetcher={generalDebounceFetcher((value) => FindTenants(false, value), true, undefined, 1)}
      dropdownClassName={dropdownClassName}
      combineType="right"
      handleOptionChange={multiHandleChange('combineName')}
      renderOption={renderMultiOptions({
        val: 'combineName',
        label: 'combineName',
      })}
      onOptionsChange={(tenants) => {
        onFilter({ ...filter, tenants })
      }}
      searchButtonMerged
      customWidth="200px"
      placeholderWidth="180px"
    />
  )

  const renderListingTitlesSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      clearSearchAfterSelect
      onClear={() => onFilter({ ...filter, listingTitle: undefined })}
      placeholder={listingTitlesText}
      dropDownList={[]}
      defaultValues={filter?.listingTitle}
      fieldForValue="title"
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      handlePlaceholder={multiHandlePlaceholder('title', listingTitlesText)}
      debounceFetcher={generalDebounceFetcher((value) => FindListings(value), true, undefined, 2)}
      dropdownClassName={dropdownClassName}
      combineType="both"
      handleOptionChange={multiHandleChange('title')}
      renderOption={renderMultiOptions({
        val: 'title',
        label: 'title',
      })}
      onOptionsChange={(listings: ListingRef[]) => {
        onFilter({ ...filter, listingTitle: listings.map(({ title }) => title) })
      }}
      searchButtonMerged
      customWidth="200px"
      placeholderWidth="120px"
    />
  )

  const renderProgramsSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      onClear={() => onFilter({ ...filter, programs: undefined })}
      clearSearchAfterSelect
      placeholder={programTabProgramSelectorText}
      dropDownList={[]}
      showMagnifySVG={false}
      fieldForValue="id"
      maxTagCount={0}
      isDebounceFetcher={true}
      handlePlaceholder={multiHandlePlaceholder('name', programTabProgramSelectorText)}
      debounceFetcher={generalDebounceFetcher(
        (programName) =>
          GetAllPeakShavingPrograms({
            programName,
          }),
        true,
        'data',
      )}
      dropdownClassName={dropdownClassName}
      combineType="left"
      handleOptionChange={multiHandleChange('id')}
      renderOption={renderMultiOptions({
        val: 'id',
        label: 'name',
      })}
      onOptionsChange={(programs: PeakShavingProgram[]) => {
        onFilter({ ...filter, programs: programs.map(({ id }) => id) })
      }}
      searchButtonMerged
      customWidth="200px"
      placeholderWidth="120px"
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {renderSitesSelector}
          <LineBreak />
          {renderListingTitlesSelector}
          <LineBreak />
          {renderProgramsSelector}
          <ButtonIcon icon={magnifyBlackSVG} loading={loading} disabled={loading} fetchData={onFetch} withinSearchBar />
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {renderSitesSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderListingTitlesSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderProgramsSelector}
          </Col>
          <Col span={24}>
            <Button block type="default" disabled={loading} loading={loading} onClick={onFetch}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ParticipantsSelectors
