import { Menu, MenuProps } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import { GlobalOutlined, TagsFilled } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import { useAppState } from 'state'
import { Links, Paths } from 'components/routes/paths'
import { renderFormatMessage } from 'helpers/intl'
import { checkDriverAccess, isManagerToTenants, isTenantManager, isTenantViewer, isViewerToTenants } from 'models/user'

import { pieChart18x18SVG } from 'assets/svg/pieChart'
import { transactionWhiteSVG } from 'assets/svg/transaction'
import { tenantWhiteSVG } from 'assets/svg/tenant'
import { infoSVG } from 'assets/svg/clipboard'
import { userWhiteSVG } from 'assets/svg/user'
import { peakShavingIcon } from 'assets/svg/peakShavingIcon'
import { supportDashboardIcon } from 'assets/svg/supportDashboardIcon'
import { multiArrow } from 'assets/svg/arrow'
import configProvider from 'config'
import EVChargerSVG from 'assets/svg/ev_charging'
import { theme } from 'theme'

interface NavigationOptionProps {
  collapsed: boolean
  onCollapsed: () => void
}

const NavigationOption: React.FC<NavigationOptionProps> = ({ collapsed, onCollapsed }) => {
  const { currentUser, IsMobile, selectedTenant, language, logout, setLanguage } = useAppState()
  const location = useLocation()
  const isMobile = IsMobile()

  const changeLocal = () => {
    switch (language) {
      case 'en':
        setLanguage('fr')
        break
      case 'fr':
        setLanguage('en')
        break
    }
  }

  const handleLogout: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      logout()
      window.location.replace(Links.auth0login())
    }
  }
  const canDriverAccess =
    currentUser?.role === 'admin' ||
    isTenantManager(selectedTenant) ||
    (currentUser?.role === 'user' && checkDriverAccess(currentUser?.accesses))

  const canMangeTenant =
    configProvider.config.network === 'itsElectric' //only admin can view users and listings page in itsElectric
      ? currentUser?.role === 'admin'
      : currentUser?.role === 'admin' ||
        isTenantManager(selectedTenant) ||
        (currentUser?.role === 'user' && isManagerToTenants(currentUser.accesses))

  const canViewTenants =
    currentUser?.role === 'admin' ||
    isTenantViewer(selectedTenant) ||
    (currentUser?.role === 'user' && isViewerToTenants(currentUser.accesses))

  const itsElectricManager =
    configProvider.config.network === 'itsElectric' &&
    (isTenantManager(selectedTenant) || (currentUser?.role === 'user' && isManagerToTenants(currentUser.accesses)))

  return (
    <>
      <Menu defaultSelectedKeys={[location.pathname]} mode="inline" theme="dark">
        {canDriverAccess && (
          <>
            <Menu.Item key={Paths.dashboard}>
              {pieChart18x18SVG}
              <Link to={Links.dashboard} className="paragraph-01-regular">
                {renderFormatMessage('dashboard.navigation.dashboard', 'Dashboard')}
              </Link>
            </Menu.Item>
            {canMangeTenant && (
              <Menu.Item key={Paths.chargers}>
                <EVChargerSVG color={theme.colors.darkGray} />
                <Link to={Links.chargers()} className="paragraph-01-regular">
                  {renderFormatMessage('dashboard.navigation.chargers', 'Chargers')}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key={Paths.transactions}>
              {transactionWhiteSVG}
              <Link to={Links.transactions()} className="paragraph-01-regular">
                {renderFormatMessage('dashboard.navigation.transactions', 'Transactions')}
              </Link>
            </Menu.Item>
          </>
        )}
        {currentUser?.role !== 'user' && (
          <Menu.Item key={Paths.tenants}>
            {tenantWhiteSVG}
            <Link to={Links.tenants()} className="paragraph-01-regular">
              {renderFormatMessage('dashboard.navigation.tenants', 'Sites')}
            </Link>
          </Menu.Item>
        )}
        {(canMangeTenant || canViewTenants || itsElectricManager) && (
          <Menu.Item key={Paths.reports}>
            {infoSVG}
            <Link to={Links.reports()} className="paragraph-01-regular">
              {renderFormatMessage('dashboard.navigation.reports', 'Reports')}
            </Link>
          </Menu.Item>
        )}
        {canMangeTenant && (
          <Menu.Item key={Paths.users}>
            {userWhiteSVG}
            <Link to={Links.users()} className="paragraph-01-regular">
              {renderFormatMessage('dashboard.navigation.users', 'UsersRedesign')}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.role === 'admin' && configProvider.config.network !== 'powercharge' && (
          <Menu.Item key={Paths.demandManagement}>
            {peakShavingIcon}
            <Link to={Links.demandManagement()} className="paragraph-01-regular">
              {renderFormatMessage('dashboard.peakShaving', 'Peak Shaving')}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.role === 'admin' && configProvider.config.network !== 'powercharge' && (
          <Menu.Item key={Paths.supportDashboard}>
            {supportDashboardIcon}
            <Link to={Links.supportDashboard()} className="paragraph-01-regular">
              {renderFormatMessage('dashboard.supportDashboard', 'Support Dashboard')}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.role === 'admin' && configProvider.config.network !== 'powercharge' && (
          <Menu.Item
            key={Paths.tagging}
            title={
              <Link to={Links.tagging()} className="paragraph-01-regular">
                {renderFormatMessage('dashboard.tagging', 'Tagging')}
              </Link>
            }
          >
            <TagsFilled style={{ fontSize: 18 }} />
            <Link to={Links.tagging()} className="paragraph-01-regular">
              {renderFormatMessage('dashboard.tagging', 'Tagging')}
            </Link>
          </Menu.Item>
        )}
        {isMobile ? (
          <>
            <Menu.SubMenu
              key="language"
              title="EN | FR"
              icon={<GlobalOutlined />}
              style={{ bottom: '52px', position: 'absolute', left: '4%', width: collapsed ? '5rem' : 'fit-content' }}
              theme="light"
            >
              <Menu.Item
                className="mobile-language"
                disabled={language === 'en' ? true : false}
                key="en"
                onClick={changeLocal}
              >
                EN - ENGLISH
              </Menu.Item>
              <Menu.Item
                className="mobile-language"
                disabled={language === 'fr' ? true : false}
                key="fr"
                onClick={changeLocal}
              >
                FR - FRANÇAIS
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item
              key="logout"
              icon={<FontAwesomeIcon icon={faSignOutAlt} />}
              onClick={handleLogout}
              style={{ position: 'absolute', bottom: 0, width: '70%' }}
            >
              {renderFormatMessage('dashboard.header.logout', 'Logout')}
            </Menu.Item>
          </>
        ) : null}
      </Menu>
      <div className={`toggleNavigation ${collapsed ? '' : 'expanded'}`} onClick={onCollapsed}>
        {multiArrow}
      </div>
    </>
  )
}

export default NavigationOption
