import { useEffect } from 'react'
import { Space } from 'antd'

import { useAppState } from 'state'
import { ButtonIcon } from 'atom/button'
import { LineBreak } from 'atom/line-break'

import { magnifyBlackSVG } from 'assets/svg/magnify'

import { ReusableSelector } from 'components/reusableSelector/selector'

import { useTagging } from 'hooks/useTagging'
import { useSelector } from 'hooks/useSelector'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'

import { Tagging } from 'models/tagging'
import { TenantRef } from 'models/tenant'
import { ChargerRef } from 'models/charger'
import { ListingRef } from 'models/listing'
import { ChargersPerTenantFilter } from 'models/filter'
import { MasterAccountRef } from 'models/master-account'
import { ConnectorServiceStatus, ConnectorStatus, connectorServiceStatus, connectorStatus } from 'models/connector'
import { FilterValueMap } from 'types/ChargersPerTenantType'

interface props {
  loading: boolean
  filters: ChargersPerTenantFilter
  vendors: string[]
  onFetch: () => void
  onClearFilter: (filterKey: keyof ChargersPerTenantFilter) => void
  onFilterChange: (key: keyof FilterValueMap, value: any) => void
}

export const ChargersSelectors: React.FC<props> = ({
  loading,
  filters,
  vendors,
  onFetch,
  onClearFilter,
  onFilterChange,
}) => {
  const { currentUser } = useAppState()

  const { accountNamesText, locationTextPlaceholder } = useSelectorTranslation()
  const { chargingStatusText, chargerIdsText, serialNumbersText } = useChargerTranslation()
  const { serviceStatusText, makesText } = useListingBasicTrans()
  const { tagsText } = useSelectorTranslation()

  const {
    multiTenantDebounceFetcher,
    multiListingTitleAndIdDebounceFetcher,
    multiSerialNumberSelectorDebounceFetcher,
    multiMasterAccountDebounceFetcher,
    multiTenantHandleChange,
    multiSerialNumberIdHandleChange,
    multiListingTitleAndIdHandleChange,
    multiVendorPlaceholder,
    multiOCPPStatusPlaceholder,
    multiTenantHandlePlaceholder,
    multiServiceStatusPlaceholder,
    multiTaggingHandlePlaceholder,
    multiSerialNumberHandlePlaceholder,
    multiListingTitleHandlePlaceholder,
    multiMasterAccountHandlePlaceholder,
    multiTenantHandleValue,
    multiSerialNumberHandleValue,
    multiMasterAccountHandleValue,
    multiListingTitleAndIdHandleValue,
    multiTaggingHandleChange,
    multiVendorsHandleChange,
    multiOCPPStatusHandleChange,
    multiServiceStatusHandleChange,
    multiMasterAccountHandleChange,
    renderMultiVendorOption,
    renderChargerSerialNumberOption,
    renderMultiListingIdOption,
    renderMultiTenantOption,
    renderOCPPStatusOption,
    renderMultiMasterAccountOption,
    renderServiceStatusOption,
  } = useSelector()

  const { getTaggingsWithoutPagination, taggings, renderMultiTagOption } = useTagging()

  useEffect(() => {
    if (currentUser?.role === 'admin') {
      getTaggingsWithoutPagination('Listing')
    }
  }, [])

  const renderSelector = (props: any): React.ReactElement => (
    <ReusableSelector
      {...props}
      showSearch
      isSingle={false}
      maxTagCount={0}
      loading={loading}
      // other common props
    />
  )

  const renderMultiVendorSelector = renderSelector({
    showArrow: true,
    onOptionsChange: (opts: string[]) => onFilterChange('vendors', opts),
    onClear: () => onClearFilter('vendors'),
    combineType: 'both',
    customWidth: '140px',
    placeholder: makesText,
    dropdownClassName: 'not-in-modal-selector',
    renderOption: renderMultiVendorOption,
    dropDownList: vendors,
    showMagnifySVG: false,
    nativeSearch: true,
    isDebounceFetcher: false,
    debounceFetcher: undefined,
    handlePlaceholder: multiVendorPlaceholder,
    handleOptionChange: multiVendorsHandleChange,
  })

  const renderMasterAccountSelector = renderSelector({
    showArrow: false,
    onOptionsChange: (opts: MasterAccountRef[]) => onFilterChange('masterAccounts', opts),
    onClear: () => onClearFilter('masterAccounts'),
    combineType: 'both',
    customWidth: '160px',
    placeholder: accountNamesText,
    showMagnifySVG: false,
    isDebounceFetcher: true,
    fieldForValue: 'id',
    defaultValues: filters.masterAccounts,
    debounceFetcher: multiMasterAccountDebounceFetcher,
    handlePlaceholder: multiMasterAccountHandlePlaceholder,
    handleOptionChange: multiMasterAccountHandleChange,
    handleValue: multiMasterAccountHandleValue,
    renderOption: renderMultiMasterAccountOption,
  })

  const renderMultiTenantSelector = renderSelector({
    showArrow: false,
    onOptionsChange: (opts: TenantRef[]) => onFilterChange('tenants', opts),
    onClear: () => onClearFilter('tenants'),
    combineType: 'right',
    customWidth: '180px',
    placeholder: locationTextPlaceholder,
    fieldForValue: 'id',
    showMagnifySVG: true,
    isDebounceFetcher: true,
    defaultValues: filters.tenants,
    debounceFetcher: multiTenantDebounceFetcher,
    handlePlaceholder: multiTenantHandlePlaceholder,
    handleOptionChange: multiTenantHandleChange,
    handleValue: multiTenantHandleValue,
    renderOption: renderMultiTenantOption,
  })

  const renderMultiSerialNumberSelector = (
    <ReusableSelector
      showSearch
      loading={loading}
      showArrow={false}
      onOptionsChange={(opts: ChargerRef[]) => onFilterChange('chargers', opts)}
      combineType="both"
      fieldForValue="id"
      dropdownClassName="not-in-modal-selector"
      onClear={() => onClearFilter('chargers')}
      customWidth="160px"
      isSingle={false}
      placeholder={serialNumbersText}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={multiSerialNumberSelectorDebounceFetcher}
      handleOptionChange={multiSerialNumberIdHandleChange}
      renderOption={renderChargerSerialNumberOption}
      handleValue={multiSerialNumberHandleValue}
      handlePlaceholder={multiSerialNumberHandlePlaceholder}
    />
  )

  const renderMultiListingSelector = (
    <ReusableSelector
      showSearch
      onOptionsChange={(opts: ListingRef[]) => onFilterChange('listings', opts)}
      onClear={() => onClearFilter('listings')}
      combineType="both"
      customWidth="160px"
      isSingle={false}
      fieldForValue="id"
      placeholder={chargerIdsText}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={multiListingTitleAndIdDebounceFetcher}
      showArrow={false}
      handlePlaceholder={multiListingTitleHandlePlaceholder}
      handleOptionChange={multiListingTitleAndIdHandleChange}
      renderOption={renderMultiListingIdOption}
      handleValue={multiListingTitleAndIdHandleValue}
    />
  )

  const renderServiceStatusSelector = (
    <ReusableSelector
      showSearch
      loading={loading}
      onOptionsChange={(opts: ConnectorServiceStatus[]) => onFilterChange('serviceStatuses', opts)}
      onClear={() => onClearFilter('serviceStatuses')}
      combineType="both"
      customWidth="160px"
      isSingle={false}
      placeholder={serviceStatusText}
      dropdownClassName="not-in-modal-selector"
      defaultValues={filters.serviceStatuses}
      renderOption={renderServiceStatusOption}
      dropDownList={connectorServiceStatus.map((v) => v)}
      showMagnifySVG={false}
      maxTagCount={0}
      nativeSearch
      isDebounceFetcher={false}
      debounceFetcher={undefined}
      handlePlaceholder={multiServiceStatusPlaceholder}
      handleOptionChange={multiServiceStatusHandleChange}
    />
  )

  const renderOCPPStatusSelector = (
    <ReusableSelector
      showSearch
      loading={loading}
      onOptionsChange={(opts: ConnectorStatus[]) => onFilterChange('ocppStatuses', opts)}
      onClear={() => onClearFilter('ocppStatuses')}
      combineType={currentUser?.role === 'admin' ? 'both' : 'left'}
      customWidth={currentUser?.role === 'admin' ? '160px' : '200px'}
      isSingle={false}
      placeholder={chargingStatusText}
      dropdownClassName="not-in-modal-selector"
      defaultValues={filters.ocppStatuses}
      renderOption={renderOCPPStatusOption}
      dropDownList={connectorStatus.map((v) => v)}
      showMagnifySVG={false}
      maxTagCount={0}
      nativeSearch
      isDebounceFetcher={false}
      debounceFetcher={undefined}
      handlePlaceholder={multiOCPPStatusPlaceholder}
      handleOptionChange={multiOCPPStatusHandleChange}
    />
  )

  const renderTagSelector = (
    <ReusableSelector
      showSearch
      loading={loading}
      isSingle={false}
      clearSearchAfterSelect
      placeholder={tagsText}
      dropDownList={taggings}
      showMagnifySVG={false}
      defaultValues={filters?.taggings}
      fieldForValue="id"
      maxTagCount={0}
      nativeSearch
      isDebounceFetcher={false}
      handlePlaceholder={multiTaggingHandlePlaceholder}
      debounceFetcher={undefined}
      dropdownClassName="not-in-modal-selector"
      combineType="left"
      handleOptionChange={multiTaggingHandleChange}
      renderOption={renderMultiTagOption}
      onOptionsChange={(taggings: Tagging[]) => onFilterChange('taggings', taggings)}
      customWidth="160px"
      searchButtonMerged
      placeholderWidth="50px"
    />
  )

  return (
    <Space wrap size={[0, 6]}>
      {renderMultiTenantSelector}
      {currentUser?.role === 'admin' && (
        <>
          <LineBreak />
          {renderMasterAccountSelector}
        </>
      )}
      <LineBreak />
      {renderMultiListingSelector}
      <LineBreak />
      {renderMultiSerialNumberSelector}
      <LineBreak />
      {renderMultiVendorSelector}
      <LineBreak />
      {renderServiceStatusSelector}
      <LineBreak />
      {renderOCPPStatusSelector}
      {currentUser?.role === 'admin' && (
        <>
          <LineBreak />
          {renderTagSelector}
        </>
      )}
      <ButtonIcon icon={magnifyBlackSVG} loading={loading} disabled={loading} fetchData={onFetch} withinSearchBar />
    </Space>
  )
}
