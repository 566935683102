import React, { useEffect } from 'react'
import { RouteComponentProps, RouteProps } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'

import { log } from '../../logger'
import { useAppState } from '../../state'
import { Links, Paths } from './paths'

import { TenantsPage } from '../../pages/tenants/tenants-page'
import { TransactionPage } from '../../pages/transactions/transaction-page'
import { ChargerPage } from '../../pages/chargers/charger-page'
import { Auth0LoginPage } from '../../pages/authentication/auth0-login-page'
import { Auth0CallbackFunc } from '../../pages/authentication/auth0-callback-page'
import { TenantSelectPage } from '../../pages/authentication/tenant-selector-page'
import { DisconnectionStatsPage } from '../../pages/analytics/disconnection-stats-page'
import { DashboardPage } from '../../pages/dashboard/dashboard-home-page'
import { ListingPageV2 } from '../../pages/listing/listing-page-v2'
import { TenantCreatePage } from '../../pages/tenants/tenant-create-page'
import { TenantPageV2 } from '../../pages/tenants/tenant-page-v2'
import { TransactionsHomePage } from '../../pages/transactions'
import { ReportsRedesignPage } from '../../pages/reports/redesign/reports-page-redesign'
import { PeakShavingProgramPage } from '../../pages/peakShaving/peak-shaving-program-page'
import { PeakShavingProgramsPage } from '../../pages/peakShaving/peak-shaving-programs-page'
import { SupportDashboardPage } from '../../pages/supportDashboard/support-dashboard-page'
import { ClassicUsersPage } from '../../pages/users/classic-users-page'
import { UsersHomePage } from '../../pages/users/users-home-page'
import configProvider from 'config'
import { TaggingPage } from 'pages/tagging/tagging-page'
import { NewChargersPage } from 'pages/chargers/chargers-page2'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics'
import { DemandManagementPage } from 'pages/demandManagement/demand-management-page'

export const LoggedInPath = Links.onboard()

export const Routes: React.FC = () => {
  const { hydrateSession } = useAppState()
  useEffect(() => {
    log('hydrating session')
    hydrateSession()
  }, [])

  const authenticatedRoutes: {
    [path: string]: {
      component: React.ComponentType<any>
      accessibility: {
        admin: boolean
        manager: boolean
        user: boolean
      }
    }
  } = {
    [Paths.onboard]: { component: TenantSelectPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.dashboard]: { component: DashboardPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.chargers]: { component: NewChargersPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.charger]: { component: ChargerPage, accessibility: { user: true, admin: true, manager: true } },
    // [Paths.listings]: { component: ListingsPageV2, accessibility: { user: false, admin: true, manager: true } },
    // [Paths.listingCreate]: {
    //   component: ListingCreatePageV2,
    //   accessibility: { user: false, admin: true, manager: false },
    // },
    [Paths.listing]: { component: ListingPageV2, accessibility: { user: false, admin: true, manager: false } },
    [Paths.transactions]: {
      component: TransactionsHomePage,
      accessibility: { user: true, admin: true, manager: true },
    },
    [Paths.transaction]: { component: TransactionPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.tenants]: { component: TenantsPage, accessibility: { user: false, admin: true, manager: false } },
    [Paths.tenant]: { component: TenantPageV2, accessibility: { user: false, admin: true, manager: false } },
    [Paths.tenantsCreate]: { component: TenantCreatePage, accessibility: { user: false, admin: true, manager: false } },
    [Paths.analytics]: {
      component: DisconnectionStatsPage,
      accessibility: { user: false, admin: true, manager: true },
    },
    [Paths.reports]: { component: ReportsRedesignPage, accessibility: { user: false, admin: true, manager: true } },
    [Paths.users]: { component: UsersHomePage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.peakShavingPrograms]: {
      component: configProvider.config.network === 'powercharge' ? DashboardPage : PeakShavingProgramsPage,
      accessibility: { user: false, admin: true, manager: false },
    },
    [Paths.peakShavingProgram]: {
      component: configProvider.config.network === 'powercharge' ? DashboardPage : PeakShavingProgramPage,
      accessibility: { user: false, admin: true, manager: false },
    },
    [Paths.demandManagement]: {
      component: configProvider.config.network === 'powercharge' ? DashboardPage : DemandManagementPage,
      accessibility: { user: false, admin: true, manager: false },
    },
    [Paths.supportDashboard]: {
      component: configProvider.config.network === 'powercharge' ? DashboardPage : SupportDashboardPage,
      accessibility: { user: false, admin: true, manager: false },
    },
    [Paths.classicUsersPage]: {
      component: ClassicUsersPage,
      accessibility: { user: true, admin: true, manager: true },
    },
    [Paths.tagging]: {
      component: TaggingPage,
      accessibility: { user: false, admin: true, manager: false },
    },
  }

  useGoogleAnalytics(authenticatedRoutes)

  return (
    <>
      <Switch>
        {/* Authenticated-only routes */}
        {Object.keys(authenticatedRoutes).map((path) => {
          return (
            <AuthenticatedRoute
              exact={true}
              key={`authenticated-route${path.replace('/', '-')}`}
              path={path}
              component={authenticatedRoutes[path].component}
              accessibility={authenticatedRoutes[path].accessibility}
            />
          )
        })}
        {/* Unauthenticated-only routes */}
        <UnauthenticatedRoute exact={true} path={Paths.auth0login} component={Auth0LoginPage} />
        <Route path={Paths.auth0Callback} exact={true} component={Auth0CallbackFunc} />
        <Redirect to={Paths.auth0login} />
      </Switch>
    </>
  )
}

/**
 * Returns a ReactRouter.Route that can only be accessed with a valid
 * session. Accessing it with an invalid or null session with redirect
 * the user to Auth0. Upon authentication, the user will be redirect
 * to it's original route.
 */
function AuthenticatedRoute(
  props: RouteProps & {
    accessibility: {
      admin: boolean
      manager: boolean
      user: boolean
    }
  },
) {
  const { isAuthenticated, currentUser } = useAppState()
  const Component = props.component as React.ComponentType<any>
  const rest = Object.assign({}, props)
  const accessibility = props.accessibility
  delete rest.component
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps): React.ReactNode => {
        // If the user is not authenticated, redirect to signup
        if (!isAuthenticated()) {
          return <Redirect to={Links.auth0login()} />
        }

        // is the user is authenticated and he wants to go
        // to the onboarding flow send him there
        if (props.location.pathname === '/onboard') {
          return <Component {...props} />
        }

        // is the current user has not been resolved (i.e. GetSelf was NOT called on the onboarding flow)
        // send him back to onboarding to attempt to call GetSelf
        if (!currentUser) {
          log(
            'attempted to load an authentication page, current user is not set redirecting to onboard to resolve current user',
            { desiredPath: props.location.pathname },
          )
          return <Redirect to={`${Links.onboard()}?redirect_path=${props.location.pathname}`} />
        }

        if (currentUser.role === 'user' && accessibility.manager) {
          return <Component {...props} />
        }

        // if the current user is found, a tenant is selected and he is trying to access a route
        // that is reserved to admins
        if (currentUser.role === 'user' && !accessibility.user) {
          return <Redirect to={Links.onboard()} />
        }

        return <Component {...props} />
      }}
    />
  )
}

/**
 * Returns a ReactRouter.Route that can only be accessed with a valid
 * session. Accessing it with an invalid or null session with redirect
 * the user to Auth0. Upon authentication, the user will be redirect
 * to it's original route.
 */
function UnauthenticatedRoute(props: RouteProps) {
  const { isAuthenticated } = useAppState()
  const Component = props.component as React.ComponentType<any>
  const rest = Object.assign({}, props)
  delete rest.component
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        if (isAuthenticated()) {
          // If the user is authenticated and this is an auth page, redirect to onboarding
          return <Redirect to={Links.onboard()} />
        }
        return <Component {...props} />
      }}
    />
  )
}
