import { styled, theme } from '../theme'

interface props {
  isMobile?: boolean
  isTablet?: boolean
  spaceBetween?: string
}

export const EditButtonWrapper = styled.div`
  .ant-btn {
    height: 38px;
    width: 38px;
    margin-left: 1rem;
    padding: 0px;
    border-radius: 48px;
  }

  svg {
    height: 20px;
  }

  svg path {
    fill: ${theme.colors.softBlack};
    stroke: ${theme.colors.softBlack};
  }
`

export const RevenueSharePartnerSelectorWrapper = styled.div`
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    color: unset;
  }
`

export const EditButtonRowWrapper = styled.div<props>`
  .ant-btn {
    position: absolute;
    right: ${(props) => {
      if (props.isTablet) {
        return '-47px'
      } else if (props.isMobile) {
        return 0
      } else {
        return `${props.spaceBetween}rem`
      }
    }};
    top: ${(props) => (props.isMobile ? '-41px' : '0')};
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 39px;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    border-radius: 0;
  }
`

export const MasterAccountSelectorWrapper = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 39px;
    width: 132px;
    border-radius: 0;
    padding-top: 2.6px;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover {
    border-right-color: ${theme.colors.primary};
    border-left-color: ${theme.colors.primary};
  }

  .ant-select > .ant-select-selector > .ant-select-selection-search > input {
    padding-bottom: 2px;
    border-radius: 0px;
  }
`

export const HomePageMasterAccountSelectorWrapper = styled.div`
  .ant-select {
    min-width: 195px;
  }

  div > .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 39px;
    padding-top: 3.6px;
    min-width: 190px;
    width: 100%;
  }

  div > .ant-select > .ant-select-selector > .ant-select-selection-search > input {
    border-radius: 0px;
    padding-left: 20px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 1px;
    padding-left: 20px;
  }
`
