import { Button, Checkbox, Form, Input, Modal, Select } from 'antd'
import { ReactElement } from 'react'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { AccessPlanFooter, CreateAccessPlanModalWrapper } from 'atom/chargers'
import { Tenant } from 'models/tenant'
import { HorizontalLine } from 'atom/horizontal-line'
import { AccessTypeSectionModal } from './access-plan/access-type-section'
import { ScheduleSectionModal } from './access-plan/schedule-section'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { useAccessPlan } from 'hooks/useAccessPlan'
import { ChargerRef } from 'models/charger'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useSelector } from 'hooks/useSelector'

interface props {
  visible: boolean
  tenant: Tenant
  onCancel: () => void
  setVisible: (visible: boolean) => void
}

export const CreateAccessPlanModal: React.FC<props> = ({ visible, tenant, onCancel, setVisible }) => {
  const {
    form,
    initialValues,
    accessCodeChecked,
    emailDomainChecked,
    emailDomains,
    accessType,
    driversChecked,
    scheduleType,
    allChargers,
    isButtonDisabled,
    checkedDays,
    setSelectedChargers,
    setAccessCodeChecked,
    setEmailDomainChecked,
    setEmailDomains,
    handleDayCheckedChange,
    handleAccessTypeChange,
    handleScheduleTypeChange,
    handleValuesChange,
    createAccessPlan,
    setAccessType,
    setScheduleType,
  } = useAccessPlan(tenant)
  const { tenantTabChargerText } = useTenantTranslation()
  const { accessPlanNameText, createAnAccessPlanText, nameToKeepTrackText, chargerIdsText } = useAccessPlanTranslation()
  const { createText } = useGeneralTranslation()
  const { multiChargersPlaceholder, multiChargersHandleChange, multiSerialNumberHandleValue } = useSelector()

  const afterClose = () => {
    setAccessType('restricted')
    setScheduleType('24/7')
    form.resetFields()
  }

  const renderMultiChargerOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option.id} value={option.id} label={option.chargePointSerialNumber}>
      <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
        {option.chargePointSerialNumber}
      </Checkbox>
    </Select.Option>
  )

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={660}
      bodyStyle={{ padding: '0px' }}
      footer={null}
      title={<div className="heading-05-regular">{createAnAccessPlanText}</div>}
      afterClose={afterClose}
    >
      <CreateAccessPlanModalWrapper>
        <Form
          form={form}
          layout="vertical"
          requiredMark={true}
          labelAlign="left"
          initialValues={initialValues}
          onValuesChange={handleValuesChange}
          onFinish={(values) => {
            createAccessPlan(values, setVisible)
          }}
          labelWrap={true}
        >
          <div className="modal-body">
            <div className="heading-06-regular">
              {accessPlanNameText}
              <span style={{ color: 'red' }}>*</span>
            </div>
            <Form.Item name="accessPlanName">
              <Input placeholder={nameToKeepTrackText} />
            </Form.Item>

            <div className="heading-06-regular">
              {tenantTabChargerText}
              <span style={{ color: 'red' }}>*</span>
            </div>
            <Form.Item name="chargers">
              <ReusableSelector
                showSearch
                onOptionsChange={(chargersOptions: ChargerRef[]) => setSelectedChargers(chargersOptions)}
                onClear={() => setSelectedChargers([])}
                isSingle={false}
                placeholder={chargerIdsText}
                dropDownList={allChargers}
                showMagnifySVG={false}
                maxTagCount={0}
                isDebounceFetcher={false}
                showArrow={false}
                handlePlaceholder={multiChargersPlaceholder}
                handleOptionChange={multiChargersHandleChange}
                renderOption={renderMultiChargerOption}
                handleValue={multiSerialNumberHandleValue}
              />
            </Form.Item>
            <AccessTypeSectionModal
              accessType={accessType}
              driversChecked={driversChecked}
              accessCodeChecked={accessCodeChecked}
              emailDomainChecked={emailDomainChecked}
              emailDomains={emailDomains}
              handleAccessTypeChange={handleAccessTypeChange}
              setAccessCodeChecked={setAccessCodeChecked}
              setEmailDomainChecked={setEmailDomainChecked}
              setEmailDomains={setEmailDomains}
            />
            <ScheduleSectionModal
              form={form}
              scheduleType={scheduleType}
              checkedDays={checkedDays}
              handleDayCheckedChange={handleDayCheckedChange}
              handleScheduleTypeChange={handleScheduleTypeChange}
            />
          </div>
          <div>
            <HorizontalLine className="footer" />
            <AccessPlanFooter>
              <Button key="submit" htmlType="submit" type="primary" disabled={isButtonDisabled}>
                {createText}
              </Button>
            </AccessPlanFooter>
          </div>
        </Form>
      </CreateAccessPlanModalWrapper>
    </Modal>
  )
}
