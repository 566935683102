import { useFormatMessage } from '../../helpers/intl'

export const useAccessPlanTranslation = () => {
  const accessCodeText = useFormatMessage('dashboard.tenantPage.detailTab.accessCode.heading', 'Access Code')
  const monText = useFormatMessage('dashboard.mobileCalendar.week.mon', 'Mon')
  const tueText = useFormatMessage('dashboard.mobileCalendar.week.tue', 'Tue')
  const wedText = useFormatMessage('dashboard.mobileCalendar.week.wed', 'Wed')
  const thuText = useFormatMessage('dashboard.mobileCalendar.week.thu', 'Thu')
  const friText = useFormatMessage('dashboard.mobileCalendar.week.fri', 'Fri')
  const satText = useFormatMessage('dashboard.mobileCalendar.week.sat', 'Sat')
  const sunText = useFormatMessage('dashboard.mobileCalendar.week.sun', 'Sun')
  const createAnAccessPlanText = useFormatMessage('dashboard.accessPlan.createAnAccessPlan', 'Create an Access Plan')
  const accessPlanNameText = useFormatMessage('dashboard.accessPlan.accessPlanName', 'Access Plan Name')
  const customDaysText = useFormatMessage('dashboard.accessPlan.customDays', 'Custom Days')
  const unrestrictedText = useFormatMessage('dashboard.accessPlan.unrestricted', 'Unrestricted')
  const noRestrictionsOnAccessText = useFormatMessage(
    'dashboard.accessPlan.noRestrictionsOnAccess',
    'No restrictions on access to charger',
  )
  const restrictedAccessText = useFormatMessage(
    'dashboard.accessPlan.restrictedAccess',
    'Restricted access via access code or email domain',
  )
  const restrictBasedOnText = useFormatMessage('dashboard.accessPlan.restrictBasedOn', 'Restrict Based On')
  const assignedDriversText = useFormatMessage(
    'dashboard.accessPlan.assignedDrivers',
    'Assigned drivers from user page automatically get access. Change access on users page if needed.',
  )
  const usersGetAccessByAccessCodeText = useFormatMessage(
    'dashboard.accessPlan.usersGetAccessByAccessCode',
    'Users get access by entering this access code',
  )
  const usersGetAccessByEmailText = useFormatMessage(
    'dashboard.accessPlan.usersGetAccessByEmail',
    'Users get access by logging in with this email domain',
  )
  const emailDomainText = useFormatMessage('dashboard.accessPlan.emailDomain', 'Email Domain')
  const driversText = useFormatMessage('dashboard.accessPlan.drivers', 'Drivers')
  const nameToKeepTrackText = useFormatMessage('dashboard.accessPlan.nameToKeepTrack', 'A name to help you keep track')
  const chargerIdsText = useFormatMessage('dashboard.accessPlan.chargerIds', 'Charger ID(s)')
  const enterEmailDomainsText = useFormatMessage('dashboard.accessPlan.enterEmailDomains', 'Enter email domains')
  const addTimeScheduleText = useFormatMessage('dashboard.accessPlan.addTimeSchedule', 'ADD TIME SCHEDULE')

  return {
    accessCodeText,
    monText,
    tueText,
    wedText,
    thuText,
    friText,
    satText,
    sunText,
    createAnAccessPlanText,
    accessPlanNameText,
    customDaysText,
    unrestrictedText,
    noRestrictionsOnAccessText,
    restrictedAccessText,
    restrictBasedOnText,
    assignedDriversText,
    usersGetAccessByAccessCodeText,
    usersGetAccessByEmailText,
    emailDomainText,
    driversText,
    nameToKeepTrackText,
    chargerIdsText,
    enterEmailDomainsText,
    addTimeScheduleText,
  }
}
