import { Table } from 'antd'
import { useState } from 'react'
import { Tenant, TenantPayload } from '../../models/tenant'
import { Box } from '../../atom/box'
import { ButtonModal } from 'atom/button'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { plusSVG } from 'assets/svg/plus'
import { CreateAccessPlanModal } from './modal/create-access-plan-modal'

interface tenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: TenantPayload) => void
}

export const TenantTabAccessPlan: React.FC<tenantTabDetailProps> = ({ tenant, onSave }) => {
  const [openCreateAccessPlanModal, setOpenCreateAccessPlanModal] = useState(false)
  const { accessPlanText } = useTenantTranslation()

  const handleCreateAccessPlanModal = () => {
    setOpenCreateAccessPlanModal(true)
  }

  return (
    <>
      <Box padding>
        <ButtonModal
          size="large"
          click={handleCreateAccessPlanModal}
          icon={plusSVG}
          text={accessPlanText}
          type="primary"
        />
        <Table />
      </Box>
      <CreateAccessPlanModal
        visible={openCreateAccessPlanModal}
        tenant={tenant}
        onCancel={() => setOpenCreateAccessPlanModal(false)}
        setVisible={setOpenCreateAccessPlanModal}
      />
    </>
  )
}
