import { compile } from 'path-to-regexp'

export const Paths = {
  auth0login: '/connect',
  auth0Callback: '/auth0/callback',
  onboard: '/onboard',
  dashboard: '/dashboard',
  chargers: '/chargers',
  charger: '/chargers/:chargerId/:connectorId',
  chargersCompare: '/chargers/compare',
  chargersUpdateFirmware: '/chargers/updateFirmware',
  chargersChangeConfig: '/chargers/changeConfig',
  // listings: '/listings',
  listing: '/listing/:listingId',
  // listingCreate: '/listing/create',
  transactions: '/transactions',
  transaction: '/transactions/:transactionId',
  tenants: '/tenants',
  tenant: '/tenants/:tenantId',
  tenantsCreate: '/tenant/create',
  users: '/users',
  classicUsersPage: '/classicUsersPage',
  analytics: '/analytics',
  reports: '/reports',
  usersCreate: '/users/create',
  usersAdd: '/users/add',
  user: '/users/:userId',
  action: '/action',
  blankPage: '/blankPage',
  peakShavingPrograms: '/peakShavingPrograms',
  supportDashboard: '/supportDashboard',
  peakShavingProgram: '/peakShavingPrograms/:programId',
  tagging: '/tagging',
  demandManagement: '/demandManagement',
}

export const Links = {
  auth0login: compile(Paths.auth0login),
  auth0Callback: compile(Paths.auth0Callback),
  onboard: compile(Paths.onboard),
  dashboard: compile(Paths.dashboard),
  chargers: compile(Paths.chargers),
  chargersCompare: compile(Paths.chargersCompare),
  chargersUpdateFirmware: compile(Paths.chargersUpdateFirmware),
  chargersChangeConfig: compile(Paths.chargersChangeConfig),
  charger: compile(Paths.charger),
  // ! comment out until we want to remove the pages completely
  // listings: compile(Paths.listings),
  listing: compile(Paths.listing),
  // listingCreate: compile(Paths.listingCreate),
  transactions: compile(Paths.transactions),
  transaction: compile(Paths.transaction),
  tenants: compile(Paths.tenants),
  tenant: compile(Paths.tenant),
  tenantsCreate: compile(Paths.tenantsCreate),
  users: compile(Paths.users),
  analytics: compile(Paths.analytics),
  reports: compile(Paths.reports),
  usersCreate: compile(Paths.usersCreate),
  usersAdd: compile(Paths.usersAdd),
  user: compile(Paths.user),
  classicUsersPage: compile(Paths.classicUsersPage),
  action: compile(Paths.action),
  blankPage: compile(Paths.blankPage),
  peakShavingPrograms: compile(Paths.peakShavingPrograms),
  supportDashboard: compile(Paths.supportDashboard),
  peakShavingProgram: compile(Paths.peakShavingProgram),
  tagging: compile(Paths.tagging),
  demandManagement: compile(Paths.demandManagement),
}
