import { log } from 'logger'
import configProvider from '../../config'
import { apiClient } from './client'
import { AccessPlanPayload, AccessPlanResponse } from 'models/access-plan'
import { mockAccessPlan } from 'mock/access-plan-mock'
import { AccessPlansResponse } from 'models/http'

export async function CreateAccessPlan(accessPlan: AccessPlanPayload, tenantId: number): Promise<AccessPlanResponse> {
  const url = `/tenants/${tenantId}/access_plans`

  log('Creating pricing schema', { url: url })
  if (configProvider.config.mockEnabled) {
    return mockAccessPlan.data[0]
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      access_plan: { ...accessPlan },
    }),
  })
}

export async function GetAllAccessPlans(tenantId: number): Promise<AccessPlansResponse> {
  const url = `/tenants/${tenantId}/access_plans?all_access_plans=true`

  log('getting discount schemas', { url: url })
  if (configProvider.config.mockEnabled) {
    return mockAccessPlan
  }
  return apiClient(url, {})
}

export async function GetAccessPlans(
  page: number,
  tenantId: number,
  accessPlanName?: string,
): Promise<AccessPlansResponse> {
  let url = `/tenants/${tenantId}/access_plans?page=${page}`

  if (accessPlanName) {
    url = `/tenants/${tenantId}/access_plans?page=${page}&name=${accessPlanName}`
  }

  log('getting pricing schemas', { url: url })
  if (configProvider.config.mockEnabled) {
    return mockAccessPlan
  }
  return apiClient(url, {})
}
