import { message, Select, Tag } from 'antd'
import { ReactElement, useEffect, useState } from 'react'

import { renderFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { FindTenants } from '../../services/data-provider/tenants'

const { Option } = Select

interface props {
  defaultTenant?: TenantRef
  onOptionsChange: (options: TenantRef[]) => void
  onClear: () => void
}

const renderOption = (tenant: TenantRef): ReactElement => {
  return (
    <Option key={tenant.id} value={tenant.displayName || tenant.name}>
      {tenant.displayName || tenant.name}
    </Option>
  )
}

export const MultiTenantSelector: React.FC<props> = ({ defaultTenant, onOptionsChange, onClear }) => {
  const [selected, setSelected] = useState<TenantRef | undefined>(defaultTenant)
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [displayCount, setDisplayCount] = useState(false)

  const handleChange = (values: string[]) => {
    values.length > 1 ? setDisplayCount(true) : setDisplayCount(false)
    onOptionsChange(tenants.filter((t) => values.includes(t.displayName || t.name)))
  }

  useEffect(() => {
    FindTenants(false)
      .then((tenants) => setTenants(tenants))
      .catch((err: SwtchError) => {
        setTenants([])
        message.error(err.description)
      })
  }, [])

  return (
    <>
      {!selected && (
        <Select
          showSearch
          mode="multiple"
          allowClear
          style={{ minWidth: 159, width: '100%' }}
          placeholder={renderFormatMessage('dashboard.selector.searchTenants', 'Search Site(s)')}
          onClear={onClear}
          onChange={handleChange}
          dropdownMatchSelectWidth={false}
          maxTagCount={displayCount ? 'responsive' : undefined}
        >
          {tenants.length !== 0 && tenants.map((v) => renderOption(v))}
        </Select>
      )}

      {selected && (
        <Tag
          closable={true}
          onClose={(e) => {
            setSelected(undefined)
            onClear()
          }}
        >
          {selected.displayName || selected.name}
        </Tag>
      )}
    </>
  )
}
